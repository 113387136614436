import axios from "axios";

export const checkAuth = (mob, idToken) => {
  return axios.post(
    `https://gatewayprod.saveo.in/api/auth/checkAuth`,
    { mobileNumber: mob },
    {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    }
  );
};

export const sendOtp = (mob) => {
  return axios.post(
    `https://gatewayprod.saveo.in/api/authenticate/sendOtp?mobileNo=${mob}`
  );
};

export const verifyOTP = async (params) => {
  return axios({
    method: "post",
    url: "https://gatewayprod.saveo.in/api/authenticate/verifyOtp",
    params: params,
  });
};

export const verifyPass = async (params) => {
  return axios({
    method: "post",
    url: "https://gatewayprod.saveo.in/api/authenticate/verifyPassword",
    params: params,
  });
};
