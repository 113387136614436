import React, { useEffect, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel'
import { Paper } from '@material-ui/core'
import LazyLoad from 'react-lazyload';
import axios from 'axios';
import { Helmet } from 'react-helmet';

export default function Careers() {

	const [ name, setName ] = useState("");
	const [ email, setEmail ] = useState("");
	const [ roles, setRoles ] = useState("");
	const [ message, setMessage ] = useState("");
	const [ number, setNumber ] = useState("");
	const [ openings, setOpenings ] = useState([]);

	const handleSubmit = (e) => {
		e.preventDefault()

		if(name === "" || email === "" || roles === "" || message === "" || number === "")
		{
			alert("All fields are required!");
			return;
		}

		const careers = {
			name,
			email,
			number,
			roles,
			message
		};

		axios
		.post(`${process.env.REACT_APP_API}/careers`, careers)
		.then((res) => {
			console.log(res);
		})
		.catch(err => {
			console.log(err);
		});

		alert("Thanks for showing interest in us! We Will get in touch with you ASAP...");

		setName("");
		setEmail("");
		setRoles("");
		setMessage("");
		setNumber("");
	}

	useEffect(() => {
		window.scrollTo(0, 0);

		const fetchRoles = async () => {
            try {
                setOpenings(openings);
                const response = await axios.get(`${process.env.REACT_APP_API}/roles`);
                // console.log(response.data);
                setOpenings(response.data);
            } 
            catch (e) {
                console.log(e);
                setOpenings(openings);
            }
        };

        fetchRoles();
	}, [])

    return (
        <div>
		 <Helmet>
         <title>CARIN serves a wide range of products across therapies and intends to add many more to its portfolio.</title>
        <meta name="title" content="CARIN serves a wide range of products across therapies and intends to add many more to its portfolio." />
        <meta name="description" content="We at CARIN are advocators of Science and using it to advance health is our calling.
									We are an assistive, Digital-First Healthcare brand. We believe that technology can be a great leveler and make Quality Healthcare much more accessible. We strive for a better future, for all." />
        <link rel="canonical" href="https://www.carin.life/careers" />
        </Helmet>
            <section className="ls columns_padding_25">
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							<h2 className="logo-color1 txt-bold text-center">Life at Carin</h2>
							<div className="starting blog_slider">
								<Carousel showArrows={true} autoPlay autoFocus dynamicHeight infiniteLoop interval={"4000"}>
									<Paper>
										<img
										src={process.env.REACT_APP_IMAGES+ "/careers_1.jpg"}
										alt="First slide"
										/>
									</Paper>
									<Paper>
									<LazyLoad>
										<img
										src={process.env.REACT_APP_IMAGES+ "/careers_2.jpg"}
										alt="Second slide"
										/>
									</LazyLoad>
									</Paper>
									<Paper>
									<LazyLoad>
										<img
										src={process.env.REACT_APP_IMAGES+ "/careers_3.jpg"}
										alt="Third slide"
										/>
									</LazyLoad>
									</Paper>
								</Carousel>
							</div>
						</div>
					</div>
				</div>
			</section>
			<br />
			<section className="ls about_section columns_padding_25">
				<div className="container">
					<div style={{padding:"0 20px"}} className="row">
						<div style={{paddingBottom:"30px"}} className="col-sm-12 to_animate bg-color-abt" data-animation="fadeInUp" data-delay="150">
							<h3 style={{textAlign: "center"}} className="logo-color1">In the voice of Robin Williams, “Carpe Diem – Seize the day”</h3>
							<h4 className="logo-color1">And we believe the same.
							</h4>
							<br />
							<p>We work smart and we work hard and give it all with our heart. And so, while we are at it, we find all possible avenues to have fun. We celebrate our work and so do we celebrate life, to the fullest.</p>
						</div>
					</div>
				</div>
			</section>
			<section className="ls columns_padding_25">
				<div className="container">
					<div style={{padding:"0 20px"}} className="row">
						<div style={{paddingBottom:"30px"}} className="col-sm-12 to_animate bg-color-abt" data-animation="fadeInUp" data-delay="150">	
							<h3 className=" logo-color1">Culture and Values </h3>
							<p style={{fontSize: "20px"}}><span className="txt-bold">Integrity</span> and <span className="txt-bold">organizational transparency</span> are embedded in our DNA. We breathe and foster them across the value chain. 
							</p>
							<br />
							<h4 className=" logo-color1">CARIN Workspace</h4>
							<p>We aim to create a positive workspace with a dynamic workforce and <span className="txt-bold">open culture.</span>
							</p>
							<p>We embrace <span className="txt-bold">diversity</span> at all levels in the organization and value the different perspectives that come with it. We provide safe, secure space and professional freedom to all our employees, to aspire and achieve. We seek to nurture a competitive talent pool bound by a shared value system.
							</p>
							<p>We embody an <span className="txt-bold">entrepreneurial culture</span> and consistently push ourselves to take risks and think creatively. We at CARIN promise an exciting journey. We do something new, every day.</p>
							
							<p>All our employees are fiercely <span className="txt-bold">passionate</span> and their knowledge, skills and ability to innovate are the reasons for our success. We invest in their continuous learning and personal development and empower them to realize their true potential.</p>
							<span className="txt-bold">We support and care for all our employees.</span>
						</div>
					</div>
				</div>
			</section>
			<br />
			<section className="ls columns_padding_25 section_padding_top_50 section_padding_bottom_100">
				<div className="container">
					<div className="row">
						<div className="col-sm-8 to_animate" data-animation="scaleAppear" data-delay="200">
							<h3 className="logo-color1">Want to join our team:</h3>
							<form className="contact-form row columns_padding_10" name="careers" id="myForm">
								<div className="col-sm-6">
									<div className="contact-form-name"> <label for="name">Full Name <span className="required">*</span></label> <input onChange={(e)=>setName(e.target.value)} type="text" aria-required="true" size="30" value={name} name="name" id="name" className="form-control" placeholder="Full Name" /> </div>
								</div>
								<div className="col-sm-6">
									<div className="contact-form-email"> <label for="email">Email address<span className="required">*</span></label> <input onChange={(e)=>setEmail(e.target.value)} type="email" aria-required="true" size="30" value={email} name="email" id="email" className="form-control" placeholder="Email Address" /> </div>
								</div>
								<div className="col-sm-6">
									<div className="contact-form-phone"> <label for="phone">Phone Number</label> <input onChange={(e)=>setNumber(e.target.value)} type="text" size="30" value={number} name="phone" id="phone" className="form-control" placeholder="Phone Number" /> </div>
								</div>
								<div className="col-sm-6">
									<div className="contact-form-subject"> <label for="subject">Subject</label> <input onChange={(e)=>setRoles(e.target.value)} list="openRoles" name="roles" value={roles} id="roles" className="form-control" placeholder="Select Role" />
										<datalist id="openRoles">
											{
												openings.map((role) => <option value={role.roles} id={role.id}></option>)
											}
										</datalist>
									</div>
								</div>
								<div className="col-sm-12">
									<div className="contact-form-message"> <label for="message">Message</label> <textarea onChange={(e)=>setMessage(e.target.value)} aria-required="true" rows="5" cols="45" name="message" value={message} id="message" className="form-control" placeholder="Your Message"></textarea> </div>
								</div>
								<div className="col-sm-12">
									<div className="contact-form-submit topmargin_10"> <button onClick={handleSubmit} type="submit" id="contact_form_submit" name="contact_submit" className="theme_button color2 inverse min_width_button">Send Message</button> </div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
        </div>
    )
}