import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Navbar() {
  // const mediaMatch = window.matchMedia("(min-width: 990px)");
  const [matches, setMatches] = useState();
  const [isHovered, setHovered] = useState(false);

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  useEffect(() => {
    // const handler = (e) => setMatches(e.matches);
    // mediaMatch.addListener(handler);
    // return () => mediaMatch.removeListener(handler);
  }, []);

  return (
    <div>
      <section className="page_topline cs logo-color table_section table_section_sm section_padding_top_5 section_padding_bottom_5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-8 text-center text-sm-left hide-it">
              <div className="padding-left--75">
                {/* <i className="fa fa-clock-o rightpadding_5" aria-hidden="true"></i> */}
                Caring Heart . Digital Mind
              </div>
            </div>
            <div className="col-sm-4 text-center text-sm-right greylinks padding-right--75 padding-left--0">
              {" "}
              <span className="rightpadding_10">Follow Us:</span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="soc-icon color-icon soc-facebook"
                href="https://www.facebook.com/Carin.Lifesciences/"
                title="Facebook"
              >
                fb
              </a>
              {/* <a target="_blank" rel="noopener noreferrer" className="soc-icon color-icon soc-twitter" href="#" title="Twitter"></a> */}
              {/* <a target="_blank" rel="noopener noreferrer" className="soc-icon color-icon soc-google" href="#" title="Google"></a> */}
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="soc-icon color-icon soc-instagram"
                href="https://www.instagram.com/livecarin/"
                title="Instagram"
              >
                instagram
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="soc-icon color-icon soc-linkedin"
                href="https://www.linkedin.com/company/carinlife"
                title="Linkedin"
              >
                linkedin
              </a>
              {/* <a target="_blank" rel="noopener noreferrer" className="soc-icon color-icon soc-youtube" href="#" title="Youtube"></a> */}
            </div>
          </div>
        </div>
      </section>
      <header className="page_header header_white toggler_xs_right section_padding_25">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 display_table">
              <div className="header_left_logo display_table_cell">
                {" "}
                <a className="logo top_logo" href="/">
                  <img
                    className="carin_logo"
                    src={
                      "https://d214gcurai71bm.cloudfront.net/carin-source-files/carin-images" +
                      "/carin.svg"
                    }
                    alt=""
                  />
                </a>
              </div>
              <div className="header_mainmenu display_table_cell text-center">
                <nav className="mainmenu_wrapper">
                  <ul className="mainmenu nav sf-menu">
                    <li className={splitLocation[1] === "" ? "active" : ""}>
                      {" "}
                      {matches ? (
                        <a href="/">Home</a>
                      ) : (
                        <a href="/">Home</a>
                      )}
                    </li>
                    <li
                      className={splitLocation[1] === "about" ? "active" : ""}
                    >
                      {" "}
                      {matches ? (
                        <Link to="/about">About Us</Link>
                      ) : (
                        <Link to="/about">About Us</Link>
                      )}
                    </li>
                    <li
                      onMouseEnter={() => setHovered(true)}
                      onMouseLeave={() => setHovered(false)}
                      className={
                        splitLocation[1] === "offerings"
                          ? isHovered
                            ? "active sfHover"
                            : "active"
                          : isHovered
                          ? "sfHover"
                          : ""
                      }
                    >
                      {" "}
                      {matches ? (
                        <Link className="sf-with-ul" to="/offerings">
                          Our Offerings
                        </Link>
                      ) : (
                        <Link className="sf-with-ul" to="/offerings">
                          Our Offerings
                        </Link>
                      )}
                      <ul
                        style={
                          isHovered
                            ? { display: "block", marginTop: 0 }
                            : { display: "none", marginTop: "5px" }
                        }
                      >
                        <li>
                          {" "}
                          {matches ? (
                            <Link to="/offerings/gynaecology">Gynaecology</Link>
                          ) : (
                            <Link to="/offerings/gynaecology">Gynaecology</Link>
                          )}
                        </li>

                        <li>
                          {" "}
                          {matches ? (
                            <Link to="/offerings/cardiovascular_metabolics">
                              Cardiovascular Metabolics
                            </Link>
                          ) : (
                            <Link to="/offerings/cardiovascular_metabolics">
                              Cardiovascular Metabolics
                            </Link>
                          )}
                        </li>
                        <li>
                          {" "}
                          {matches ? (
                            <Link to="/offerings/anti-diabetics">
                              {" "}
                              Anti-Diabetics
                            </Link>
                          ) : (
                            <Link to="/offerings/anti-diabetics">
                              {" "}
                              Anti-Diabetics
                            </Link>
                          )}
                        </li>
                        <li>
                          {" "}
                          {matches ? (
                            <Link to="/offerings/respiratory">Respiratory</Link>
                          ) : (
                            <Link to="/offerings/respiratory">Respiratory</Link>
                          )}
                        </li>
                        <li>
                          {" "}
                          {matches ? (
                            <Link to="/offerings/dermatology">Dermatology</Link>
                          ) : (
                            <Link to="/offerings/dermatology">Dermatology</Link>
                          )}
                        </li>
                        <li>
                          {" "}
                          {matches ? (
                            <Link to="/offerings/analgesics">
                              {" "}
                              Pain/Analgesics
                            </Link>
                          ) : (
                            <Link to="/offerings/analgesics">
                              {" "}
                              Pain/Analgesics
                            </Link>
                          )}
                        </li>
                        <li>
                          {" "}
                          {matches ? (
                            <Link to="/offerings/anti-infectives">
                              {" "}
                              Anti-infectives
                            </Link>
                          ) : (
                            <Link to="/offerings/anti-infectives">
                              {" "}
                              Anti-infectives
                            </Link>
                          )}
                        </li>
                        <li>
                          {" "}
                          {matches ? (
                            <Link to="/offerings/gastrology"> Gastrology</Link>
                          ) : (
                            <Link to="/offerings/gastrology"> Gastrology</Link>
                          )}
                        </li>
                        <li>
                          {" "}
                          {matches ? (
                            <Link to="/offerings/vitamins"> Vitamins</Link>
                          ) : (
                            <Link to="/offerings/vitamins"> Vitamins</Link>
                          )}
                        </li>
                      </ul>
                    </li>
                    <li
                      className={
                        splitLocation[1] === "community" ? "active" : ""
                      }
                    >
                      {" "}
                      {matches ? (
                        <Link to="/community">Our Community</Link>
                      ) : (
                        <Link to="/community">Our Community</Link>
                      )}
                    </li>
                    <li
                      className={splitLocation[1] === "blogs" ? "active" : ""}
                    >
                      {" "}
                      {matches ? (
                        <Link to="/blogs">Our Blogs</Link>
                      ) : (
                        <Link to="/blogs">Our Blogs</Link>
                      )}{" "}
                    </li>
                    <li
                      className={splitLocation[1] === "careers" ? "active" : ""}
                    >
                      {" "}
                      {matches ? (
                        <Link to="/careers">Careers</Link>
                      ) : (
                        <Link to="/careers">Careers</Link>
                      )}
                    </li>
                    <li
                      className={splitLocation[1] === "contact" ? "active" : ""}
                    >
                      {matches ? (
                        <Link to="/contact">Contact</Link>
                      ) : (
                        <Link to="/contact">Contact</Link>
                      )}
                    </li>
                  </ul>
                </nav>
                <span className="toggle_menu">
                  <span></span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
