import React from "react";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

export default function OfferingsCarousel(props) {
  return (
    <div id={props.data.href} className="col-sm-12 to_animate products">
      <article className="post side-item side-md content-padding with_border rounded topmargin_30 products-article">
        <div className="row">
          <div className="col-md-4 margin_auto">
            <div className="item-media top_rounded overflow_hidden products-img">
              {props.data.img_display !== null &&
              props.data.img_display !== "" ? (
                <img
                  src={
                    process.env.REACT_APP_IMAGES +
                    "/square/" +
                    props.data.img_display +
                    ".png"
                  }
                  alt="product"
                />
              ) : (
                <img
                  src="https://d214gcurai71bm.cloudfront.net/carin-images/images/events/08.jpg"
                  alt="product"
                />
              )}
              <div className="media-links"> </div>
            </div>
          </div>
          <div className="col-md-8 offerings_display">
            <div className="item-content">
              <Link to={`/${props.offering}/${props.data.href}`}>
                <h3
                  style={{ marginBottom: "5px", width: "fit-content" }}
                  className="entry-title offering_product_title logo-color1"
                >
                  {props.data.name_display}
                </h3>
              </Link>
              <h5 style={{ marginTop: 0 }} className="logo-color2">
                {props.data.salt}
              </h5>
              <ul className="nav-unstyled darklinks" role="tablist">
                <li className="active">
                  <a
                    href={"#" + props.data.href + props.idx + "1"}
                    role="tab"
                    data-toggle="tab"
                  >
                    Mode of Action
                  </a>
                </li>
                <li>
                  {props.data.classes_of_drugs === null ||
                  props.data.classes_of_drugs === "" ? (
                    <p></p>
                  ) : (
                    <a
                      href={"#" + props.data.href + props.idx + "2"}
                      role="tab"
                      data-toggle="tab"
                    >
                      Class of Drugs
                    </a>
                  )}{" "}
                </li>
                <li>
                  {props.data.benefits === null ||
                  props.data.benefits === "" ? (
                    <p></p>
                  ) : (
                    <a
                      href={"#" + props.data.href + props.idx + "3"}
                      role="tab"
                      data-toggle="tab"
                    >
                      Benefits
                    </a>
                  )}{" "}
                </li>
                <li>
                  {props.data.side_effects === null ||
                  props.data.side_effects === "" ? (
                    <p></p>
                  ) : (
                    <a
                      href={"#" + props.data.href + props.idx + "4"}
                      role="tab"
                      data-toggle="tab"
                    >
                      Side Effects
                    </a>
                  )}{" "}
                </li>
              </ul>
              <div className="tab-content tab-unstyled">
                <div
                  className="tab-pane fade in active"
                  id={props.data.href + props.idx + "1"}
                >
                  {parse(`${props.data.mode_of_action}`)}
                </div>
                <div
                  className="tab-pane fade"
                  id={props.data.href + props.idx + "2"}
                >
                  {parse(`${props.data.classes_of_drugs}`)}
                </div>
                <div
                  className="tab-pane fade"
                  id={props.data.href + props.idx + "3"}
                >
                  {parse(`${props.data.benefits}`)}
                </div>
                <div
                  className="tab-pane fade"
                  id={props.data.href + props.idx + "4"}
                >
                  {parse(`${props.data.side_effects}`)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
}
