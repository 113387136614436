import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
// import { auth } from "../../firebase";
// import firebase from "firebase/app";
import "firebase/auth";
import {
  // checkAuth,
  sendOtp,
  verifyOTP,
  verifyPass,
} from "./../../Utility/ApiService";

import "./admin.css";
export default function Login() {
  const mobileRef = useRef();
  const error = "";
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [loginType, setLoginType] = useState("pass");
  const [pass, setPass] = useState("");
  const [otp, setOtp] = useState("");
  const [mobile, changeMobile] = useState("");
  const passChange = (e) => {
    setPass(e.target.value.trim());
  };
  const otpChange = (e) => {
    setOtp(e.target.value.trim());
  };

  const mobileChange = (e) => {
    changeMobile(e.target.value.trim());
  };

  const sendOtpToMobile = () => {
    if (mobile && mobile.length) {
      setLoginType("otp");
      sendOtp(mobile)
        .then((res) => {})
        .catch((error) => {
          window.alert("Something went wrong, Please try after sometime");
        });
    } else {
      window.alert("Please enter mobile number");
    }
  };

  // useEffect(() => {
  // auth.onAuthStateChanged((user) => {
  //   if (user) {
  //     user.getIdToken().then(function (idToken) {
  //       var mobile = user.phoneNumber.substring(3);
  //       checkAuth(mobile, idToken).then((res) => {
  //         // console.log(res.data.data.user);
  //         if (res.data.data.user.admin && res.data.data.user.enabled) {
  //           history.push("/admin/home");
  //         } else console.log("Not signed in");
  //       });
  //     });
  //   } else {
  //     // User is signed out
  //     console.log("Not signed in");
  //   }
  // });
  // }, []);

  async function handleSubmit(e) {
    e.preventDefault();

    if (loginType === "otp") {
      if (otp && otp.length) {
        verifyOTP({ mobileNo: mobile, OTP: otp })
          .then((res) => {
            // checkAuth()
            let data = res.data.data;
            console.log(data);
            localStorage.setItem("authData", JSON.stringify(data));
            localStorage.setItem("mobileNo", mobile);

            history.push("/admin/home");
          })
          .catch((error) => {
            window.alert("Something went wrong, Please try after sometime");
          });
      } else {
        window.alert("Please enter valid otp");
      }
    } else if (loginType === "pass") {
      const params = {
        mobileNo: mobile,
        password: pass,
      };

      if (pass && pass.length) {
        verifyPass(params)
          .then((res) => {
            // checkAuth()
            let data = res.data.data;
            console.log(data);
            localStorage.setItem("authData", JSON.stringify(data));
            localStorage.setItem("mobileNo", mobile);

            history.push("/admin/home");
          })
          .catch((error) => {
            window.alert("Something went wrong, Please try after sometime");
          });
      } else {
        window.alert("Please enter password");
      }
    }
    setLoading(false);
  }

  return (
    <div className="container" style={{ marginTop: "100px" }}>
      {loading ? (
        "Loading..."
      ) : (
        <div className="col-sm-4 col-sm-offset-4">
          <h2 style={{ color: "black" }}>
            <span className="fa fa-sign-in"></span> Login
          </h2>
          <form id="login">
            <div className="form-group">
              <label style={{ fontWeight: "bold" }}>Mobile</label>
              <input
                type="text"
                className="form-control"
                name="mobile"
                ref={mobileRef}
                onChange={mobileChange}
              />
            </div>
            {loginType === "pass" && (
              <div className="form-group">
                <label style={{ fontWeight: "bold" }}>Enter Password</label>
                <input
                  type="text"
                  className="form-control"
                  name="mobile"
                  onChange={passChange}
                />
              </div>
            )}

            {loginType === "otp" && (
              <div className="form-group">
                <label style={{ fontWeight: "bold" }}>Enter OTP</label>
                <input
                  type="number"
                  className="form-control"
                  name="mobile"
                  onChange={otpChange}
                />
              </div>
            )}

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {loginType === "pass" && (
                <p className="otpButton" onClick={sendOtpToMobile}>
                  Login With OTP
                </p>
              )}
              {loginType === "otp" && (
                <p className="otpButton" onClick={() => setLoginType("pass")}>
                  Login With Password
                </p>
              )}
              <button
                onClick={handleSubmit}
                type="submit"
                name="login"
                className="theme_button color1 inverse"
              >
                Login
              </button>
            </div>
          </form>
          <br />
          <div id="recaptcha-container"></div>
          <p class="login-err">{error}</p>
          <hr />
          <p>
            Or go back to <a href="/">starting screen</a>.
          </p>
          <br />
          <br />
          <div className="text-center">
            <p>
              © Copyright 2021. All Rights Reserved. <a href="/">Carin</a>
            </p>
            <p>
              Visit our <a href="/"> site</a>.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
