import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useLocation } from "react-router-dom";

export default function OfferingEdit (){

    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const off = splitLocation[3];
    const type = off.split('&')[0];
    const id = off.split('&')[1];

    const [ loading, setLoading ] = useState(false);
    const [ inputs, setInputs ] = useState({});

	useEffect(() => {
		window.scrollTo(0, 0);

        const fetchOffering = async () => {
            try {
                setInputs(inputs);
                const response = await axios.get(`${process.env.REACT_APP_API}/offerings/?offering=${type}&id=${id}`);
                // console.log(response.data);
                setInputs(response.data);
                setLoading(false);
            } 
            catch (e) {
                console.log(e);
                setInputs(inputs);
            }
        };

        fetchOffering();

	}, [])

    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));

    const editThis = async (e) => {
        e.preventDefault();

        var answer = window.confirm("Are you sure?");

        if(answer) {
            await axios.post(`${process.env.REACT_APP_API}/offerings/edit/${type}`, {offering: inputs})
            .then((res) => {
                console.log(res);
                setLoading(true);
            })
        }
        else {
            return;
        }

    }

    return (
        <div className="container">
            <Button variant="contained" startIcon={<ArrowBackIcon />} style={{fontSize: "16px", backgroundColor: "orange"}} href="/admin/offerings/all"> Go Back</Button>    
            <h3 className="logo-color1" style={{marginTop:'25px', marginBottom:"15px"}}>{ type }</h3>
            <form method="POST" className="form-horizontal" id="offering-edit">
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="href">URL Endpoint name:</label>
                    </div>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" onChange={handleChange} value={ inputs.href } id="href" placeholder="Enter the name for URL endpoint" name="href" />
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="name_caro">Name for Carousel:</label>
                    </div>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" onChange={handleChange} value={ inputs.name_caro } id="name_caro" placeholder="Enter the name" name="name_caro" />
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="name_display">Name for Display:</label>
                    </div>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" onChange={handleChange} value={ inputs.name_display } id="name_display" placeholder="Enter the name" name="name_display" />
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="salt">Salt:</label>
                    </div>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" onChange={handleChange} value={ inputs.salt } id="salt" placeholder="Enter the salt" name="salt" />
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="img_caro">Image for Carousel:</label>
                    </div>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" onChange={handleChange} value={ inputs.img_caro } id="img_caro" placeholder="Enter the img_caro link" name="img_caro" />
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="img_display">Image for Display:</label>
                    </div>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" onChange={handleChange} value={ inputs.img_display } id="img_display" placeholder="Enter the img_display link" name="img_display" />
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="mode_of_action">Mode of action:</label>
                    </div>
                    <div className="col-sm-10">
                        <textarea rows="5" type="text" className="form-control" id="mode_of_action" onChange={handleChange} value={ inputs.mode_of_action } placeholder="Mode of Action" name="mode_of_action"></textarea>
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="benefits">Benefits:</label>
                    </div>
                    <div className="col-sm-10">
                        <textarea rows="10" type="text" className="form-control" id="benefits" onChange={handleChange} placeholder="Benefits" value={ inputs.benefits } name="benefits"></textarea>
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="side_effects">Side Effects:</label>
                    </div>
                    <div className="col-sm-10">
                        <textarea rows="5" type="text" className="form-control" id="side_effects" onChange={handleChange} placeholder="Side effects" value={ inputs.side_effects } name="side_effects"></textarea>
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="classes_of_drugs">Classes of drugs:</label>
                    </div>
                    <div className="col-sm-10">
                        <textarea rows="5" type="text" className="form-control" id="classes_of_drugs" onChange={handleChange} placeholder="Classes of drugs" value={ inputs.classes_of_drugs } name="classes_of_drugs"></textarea>
                    </div>
                </div>
                {
                    loading && <p className="edit-success" style={{textAlign: "center", fontWeight: "bold"}}>Your changes are saved!</p>
                }
                <div className="form-group" style={{paddingTop: "15px"}}>        
                    <div className="col-sm-offset-4 col-sm-4" style={{textAlign: "center"}}>
                        <Button variant="outlined" color="secondary" style={{fontSize: "16px"}} onClick={editThis}>Submit</Button>    
                    </div>
                </div>
            </form>
        </div>
    )
}