import React, { useEffect, useState } from "react";
import OfferingsCarousel from "./OfferingsCarousel";
import OfferingsDisplay from "./OfferingsDisplay";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Helmet } from "react-helmet";

export default function Analgesics() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [offerings, setOfferings] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchOfferings = async () => {
      try {
        setOfferings(offerings);
        const response = await axios.get(
          `${process.env.REACT_APP_API}/offerings/analgesics`
        );
        setOfferings(response.data);
      } catch (e) {
        console.log(e);
        setOfferings(offerings);
      }
    };

    fetchOfferings();
  }, []);

  return (
    <div>
     <Helmet>
         <title>Healthcare Products for Chronic Pain - CARIN Life </title>
        <meta name="title" content="Healthcare Products for Chronic Pain - CARIN Life" />
        <meta name="description" content="Chronic pain is primarily associated with arthritis, or joint pain, muscle pain, etc. Checkout products offered by CARIN Life for Chronic Pain." />
        </Helmet>
      <section className="ls section_padding_top_25">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              {" "}
              <img
                src={process.env.REACT_APP_IMAGES + "/analgesics.jpg"}
                alt=""
                className="alignleft offering_img"
              />
              <div
                className="to_animate section_padding_top_150"
                data-animation="fadeInLeft"
                data-delay="150"
              >
                <h3 className="section_header">Chronic pain and medications</h3>
                <p>
                  International Association for the Study of Pain (IASP) defined
                  chronic pain as the pain that persists beyond the normal
                  tissue healing time or beyond three months. Chronic pain is
                  primarily associated with arthritis, or joint pain, muscle
                  pain, back pain, cancer pain, headaches, or migraines,
                  neurogenic pain etc. In India, osteoarthritis is the most
                  common type of arthritis with a prevalence of 22%-39% [1].
                  Management options of chronic osteoarthritis pain are
                  medications, injections, physiotherapy, and surgery. Different
                  medication like opioids, intra-articular corticosteroids and
                  non-steroidal anti-inflammatory drugs (NSAIDs) are frequently
                  used to control inflammation and pain.
                </p>
              </div>
            </div>
            <div
              className="col-sm-12 to_animate"
              data-animation="fadeInUp"
              data-delay="150"
            >
              <br />
              <h3 className="logo-color1 product_header">Products of Carin:</h3>
            </div>
          </div>
        </div>
      </section>
      <section
        id="products"
        className="offerings ds color parallax page_gallery columns_padding_25 to_animate"
        data-animation="fadeInLeft"
        data-delay="150"
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <Carousel
                showDots={false}
                responsive={responsive}
                transitionDuration={500}
                containerClass="carousel-container"
                itemClass="carousel-item-padding-40-px"
              >
                {offerings.map((offering) => (
                  <OfferingsCarousel data={offering} />
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </section>

      <section className="ls section_padding_bottom_65">
        <div className="container">
          <div className="row">
            {offerings.length &&
              offerings.map((offering, index) => {
                return <OfferingsDisplay offering="analgesics" data={offering} idx={index + 1} />;
              })}
            <div
              className="col-sm-12 to_animate topmargin_50"
              data-animation="fadeInUp"
              data-delay="150"
            >
              <div id="accordion3" className="panel-group collapse-unstyled">
                <div className="panel">
                  <h4 className="poppins logo-color1">
                    {" "}
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion3"
                      href="#collapse11"
                    >
                      References:
                    </a>{" "}
                  </h4>
                  <div id="collapse11" className="panel-collapse collapse">
                    <div className="panel-content">
                      {" "}
                      <ol className="list1 no-bullets">
                        <li>
                          Bala K, Bavoria S, Sahni B, Bhagat P, Langeh S, Sobti
                          S. Prevalence, risk factors, and health seeking
                          behavior for knee osteoarthritis among adult
                          population in rural Jammu–A Community based Cross
                          Sectional Study. Journal of Family Medicine and
                          Primary Care. 2020;9(10):5282.
                        </li>
                        <li>
                          Pareek A, Chandurkar N, Sharma V, Desai M, Kini S,
                          Bartakke G. A randomized, multicentric, comparative
                          evaluation of aceclofenac–paracetamol combination with
                          aceclofenac alone in Indian patients with
                          osteoarthritis flare-up. Expert opinion on
                          pharmacotherapy. 2009;10(5):727-35.
                        </li>
                        <li>
                          Brooks P, Kubler P. Etoricoxib for arthritis and pain
                          management. Therapeutics and clinical risk management.
                          2006;2(1):45.
                        </li>
                        <li>
                          Jagannathan H, Thota A, B. Kumarappa AK, Kishore G. A
                          comparative study of aceclofenac versus etoricoxib in
                          the management of acute low back pain in a tertiary
                          care hospital. Journal of drug assessment.
                          2020;9(1):60-5.
                        </li>
                        <li>
                          Predel H-G, Giannetti B, Pabst H, Schaefer A, Hug AM,
                          Burnett I. Efficacy and safety of diclofenac
                          diethylamine 1.16% gel in acute neck pain: a
                          randomized, double-blind, placebo-controlled study.
                          BMC musculoskeletal disorders. 2013;14(1):1-10.
                        </li>
                      </ol>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
