import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';

export default function OfferingsAll(){

    const [ offerings, setOfferings ] =useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ type, setType ] = useState("");
    const [ products, setProducts ] = useState("");

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [])

    const handleClick = e => {

        setOfferings(offerings);

        const fetchOfferings = async () => {
            try {
                setOfferings(offerings);
                const response = await axios.get(`${process.env.REACT_APP_API}/offerings/${type}`);
                // console.log(response.data);
                setOfferings(response.data);
                setLoading(false);
                setType("");
            } 
            catch (e) {
                console.log(e);
                setOfferings(offerings);
            }
        };

        fetchOfferings();
    }

    return (
        <div className="container">
            {/* <button type="button" className="btn btn-warning btn-lg" style={{fontWeight: "bold", fontSize: "large", marginRight: "100px"}}><a href="#">Add image</a></button> */}
            <div className="row">
                <div className="col-sm-6">
                    <div className="contact-form-subject"> <label for="offerings">Offerings</label> <input onChange={(e) => {setType(e.target.value); setProducts(e.target.value);}} list="openRoles" name="offerings" value={type} id="offerings" className="form-control" placeholder="Select Offering" />
                        <datalist id="openRoles">
                            <option value="analgesics" id="analgesics">Pain/Analgesics</option>
                            <option value="cardiovascular" id="cardiovascular">Cardiovascular Metabolics</option>
                            <option value="dermatology" id="dermatology">Dermatology</option>
                            <option value="diabetics" id="diabetics">Anti-Diabetics</option>
                            <option value="gastrology" id="gastrology">Gastrology</option>
                            <option value="gynaecology" id="gynaecology">Gynaecology</option>
                            <option value="infectives" id="infectives">Anti-Infectives</option>
                            <option value="respiratory" id="respiratory">Respiratory</option>
                            <option value="vitamins" id="vitamins">Vitamins</option>
                        </datalist>
                    </div>
                </div>
                <div className="col-sm-6" style={{textAlign: "center"}}>
                    <div className="contact-form-submit topmargin_10"> <button onClick={handleClick} id="offerings_submit" name="offerings" className="theme_button color2 inverse min_width_button">Show offerings</button> </div>
                </div>
            </div>
            <br /><br />
            <div className="row">
            {
                offerings.length && (
                <div>
                    <Button variant="outlined" color="inherit" style={{fontSize: "18px", backgroundColor:"purple", color:"whitesmoke"}} href={'/admin/offerings/add/' + products}>Add New Offering</Button>    
                    <div className = "row" style={{width: "100%"}}>
                        <table className="table table-striped styled-table col-sm-12">  
                            <thead>  
                                <tr>  
                                    <th>Sr. No</th>
                                    <th>URL name</th>
                                    <th>Display Name</th>  
                                    <th>Salt</th>
                                    <th>Display Image</th>
                                    <th>Edit</th>
                                </tr>  
                            </thead>  
                            <tbody>  
                            {
                                loading ? <p>Loading...</p> :
                                offerings.map(function (offering) { 
                                    return (
                                        <tr>  
                                            <td>{offering.id}</td>
                                            <td><a href={offering.href}>{offering.href}</a></td> 
                                            <td>{offering.name_display}</td>
                                            <td>{offering.salt}</td>
                                            <td>{offering.img_display}</td>
                                            <td>
                                                <Button variant="outlined" color="secondary" style={{fontSize: "16px"}} value={offering.id} href={'/admin/offerings/' + products + '&' + offering.id}>Edit</Button>    
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                )
            }
            </div>
        </div>
    );
}