import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';

export default function Careers_Remaining(){

    const [ roles, setRoles ] =useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ msg, setMsg ] = useState("");
    const [ add, setAdd ] = useState(false);
    const [ value, setValue ] = useState("");

	useEffect(() => {
		window.scrollTo(0, 0)

        const fetchRoles = async () => {
            try {
                setRoles(roles);
                const response = await axios.get(`${process.env.REACT_APP_API}/roles`);
                // console.log(response.data);
                setRoles(response.data);
                setLoading(false);
            } 
            catch (e) {
                console.log(e);
                setRoles(roles);
            }
        };

        fetchRoles();

	}, [])

    const handleDelete = async (e) => {
        var id = e.currentTarget.value;

        await axios.delete(`${process.env.REACT_APP_API}/roles`, {data: { id: id }})
        .then((res) => {
            // setRoles(res.data);
            console.log(res.data);
            setMsg("Please refresh the page");
        })
    }

    const addThis = async (e) => {

        await axios.post(`${process.env.REACT_APP_API}/roles`, {role: value})
        .then((res) => {
            console.log(res.data);
            setRoles(res.data);
        })

    }

    return (
        <div class="container">
            <Button variant="outlined" color="inherit" style={{fontSize: "18px", backgroundColor:"purple", color:"whitesmoke"}} onClick={(e) => {setAdd(true)}}>Add New Role</Button>
            {
                add && <div className="row">
                    <div className="form-group">
                        <div className="control-label col-sm-2"><label for="date">New role:</label>
                        </div>
                        <div className="col-sm-10">
                            <input type="text" className="form-control" onChange={(e) => {setValue(e.target.value)}} value={value} placeholder="Enter new role" name="new_role" />
                        </div>
                    </div>
                    <div className="form-group" style={{paddingTop: "15px"}}>        
                        <div className="col-sm-offset-4 col-sm-4" style={{textAlign: "center"}}>
                            <Button variant="outlined" color="secondary" style={{fontSize: "16px"}} onClick={ addThis }>Submit</Button>    
                        </div>
                    </div>
                </div>
            }
            <div class = "row">
                <table class="table table-striped styled-table col-sm-12" >  
                    <thead style={{textAlign:"center"}}>  
                        <tr>  
                            <th>Sr. No</th>
                            <th>Role</th>  
                            <th>Delete?</th>  
                        </tr>  
                    </thead>  
                    <tbody style={{textAlign:"center"}}> 
                    {
                        loading ? <p>Loading...</p> : roles.length === 0 ? <h4>No roles!</h4>
                        : roles.map((role) => {
                            return (
                                <tr>  
                                    <td>{role.id}</td>
                                    <td>{role.roles}</td>
                                    <td>
                                        <Button variant="outlined" color="secondary" style={{fontSize: "16px"}} value={role.id} onClick={handleDelete}>Delete</Button>    
                                    </td>
                                </tr>
                            );                      
                        })
                    }
                    </tbody>
                </table>
                <p className="edit-success" style={{textAlign: "center"}}>{msg}</p>
            </div>
        </div>
    );
}