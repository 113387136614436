import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';

export default function Careers_Remaining(){

    const [ contacts, setContacts ] =useState([]);
    const [ loading, setLoading ] = useState(true);

	useEffect(() => {
		window.scrollTo(0, 0)

        const fetchContacts = async () => {
            try {
                setContacts(contacts);
                const response = await axios.get(`${process.env.REACT_APP_API}/careers/to_be_contacted`);
                // console.log(response.data);
                setContacts(response.data);
                setLoading(false);
            } 
            catch (e) {
                console.log(e);
                setContacts(contacts);
            }
        };

        fetchContacts();

	}, [])

    const handleDone = async (e) => {
        var id = e.currentTarget.value;

        await axios.post(`${process.env.REACT_APP_API}/careers/to_be_contacted`, {id: id})
        .then((res) => {
            setContacts(res.data);
        })
    }

    return (
        <div class="container">
            <div class = "row">
                <table class="table table-striped styled-table col-sm-12" >  
                    <thead style={{textAlign:"center"}}>  
                        <tr>  
                            <th>Sr. No</th>
                            <th>Date</th>  
                            <th>Name</th>
                            <th>Email</th>
                            <th>Number</th>
                            <th>Roles Interested in</th>
                            <th>Message</th>
                            <th>Contacted</th>  
                        </tr>  
                    </thead>  
                    <tbody style={{textAlign:"center"}}> 
                    {
                        loading ? <p>Loading...</p> : contacts.length === 0 ? <h4>No one new to contact</h4>
                        : contacts.map((contact) => {
                            return (
                                <tr>  
                                    <td>{contact.id}</td>
                                    <td>{contact.date}</td>
                                    <td>{contact.name}</td>
                                    <td>{contact.email}</td>
                                    <td>{contact.number}</td>
                                    <td>{contact.roles}</td>
                                    <td>{contact.message}</td>
                                    <td>
                                        <Button variant="outlined" color="secondary" style={{fontSize: "16px"}} value={contact.id} onClick={handleDone}>Done</Button>    
                                    </td>
                                </tr>
                            );                      
                        })
                    }
                    </tbody>
                </table>
            </div>
        </div>
    );
}