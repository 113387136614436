import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

export default function Blogs_All(){

    const [ blogs, setBlogs ] =useState([]);
    const [ loading, setLoading ] = useState(true);

	useEffect(() => {
		window.scrollTo(0, 0)

        const fetchBlogs = async () => {
            try {
                setBlogs(blogs);
                const response = await axios.get(`${process.env.REACT_APP_API}/blogs`);
                // console.log(response.data);
                setBlogs(response.data);
                setLoading(false);
            } 
            catch (e) {
                console.log(e);
                setBlogs(blogs);
            }
        };

        fetchBlogs();

	}, [])

    return (
        <div className="container">
            {/* <button type="button" className="btn btn-warning btn-lg" style={{fontWeight: "bold", fontSize: "large", marginRight: "100px"}}><a href="#">Add image</a></button> */}
            <Button variant="outlined" color="inherit" style={{fontSize: "18px", backgroundColor:"purple", color:"whitesmoke"}} href="/admin/blogs/add">Add New Blog</Button>    
            <div className = "row" style={{width: "100%"}}>
                <table className="table table-striped styled-table col-sm-12">  
                    <thead>  
                        <tr>  
                            <th>Sr. No</th>
                            <th>URL name</th>
                            <th>Date Published</th>  
                            <th>Title</th>
                            <th>Image</th>
                            <th>Edit</th>
                        </tr>  
                    </thead>  
                    <tbody>  
                    {
                        loading ? <p>Loading...</p> :
                        blogs.map(function (blog) { 
                            return (
                                <tr>  
                                    <td>{blog.id}</td>
                                    <td>{blog.name}</td> 
                                    <td>{blog.date_published}</td>
                                    <td><Link to={blog.name}>{blog.title}</Link></td>
                                    <td><a href={blog.img}>{blog.img}</a></td>
                                    <td>
                                        <Button variant="outlined" color="secondary" style={{fontSize: "16px"}} value={blog.id} href={blog.name}>Edit</Button>    
                                    </td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        </div>
    );
}