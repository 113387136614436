import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { FooterProducts } from "./FooterProducts";
import { useStyles } from "./ProductsStyles";
import { DialogTitle, DialogContent, DialogActions } from "./ProductsDialog";
import "./Products.css";
import { PDFViewer } from "./PDFViewer";
import Lottie from "react-lottie-player";
import VerifiedAnim from "../../assets/lotties/verified.json";
import FailedAnim from "../../assets/lotties/failed.json";
import WhatsappIcon from "../../assets/png/whatsapp.png";

export default function Products(props) {
  const classes = useStyles();
  let { product_name } = props.match.params;
  const history = useHistory();
  const mediaMatch = window.matchMedia("(min-width: 765px)");
  const [matches, setMatches] = useState(mediaMatch.matches);
  const [open, setOpen] = useState(false);
  const [fopen, setFopen] = useState(false);
  const [mopen, setMopen] = useState(false);
  const [product, setProduct] = useState([]);
  // const [allProduct, setAllProduct] = useState([]);
  const [tab, setTab] = useState();
  const [title, setTitle] = useState();
  const [loading, setLoading] = useState(true);
  const [openBatchFile, setOpenBatchFile] = useState(false);
  const [showBatchModal, setShowBatchModal] = useState(false);
  const [batchNoVerify, setBatchNoVerify] = useState("");
  const [verifiedBatch, setVerifiedBatch] = useState("");
  const [selectedBatchFile, setBatchFile] = useState(null);
  const [batchFiles, setBatchFiles] = useState(null);
  const scroll = "paper";
  const [uniqueId, setUniqueId] = useState([]);
  const [backCount, setBackCount] = useState(0);
  const inpRef = useRef();
  const inpRef2 = useRef();
  const inpRef3 = useRef();
  const inpRef4 = useRef();
  const inpRef5 = useRef();

  const handleClickOpen = (e) => {
    setTab(e.target.id);
    let title = e.target.className.split(" ");
    title.shift();
    setTitle(title.join(" "));
    setOpen(true);
  };

  const handleClickFopen = (e) => {
    setTab(e.target.id);
    let title = e.target.className.split(" ");
    title.shift();
    setTitle(title.join(" "));
    setFopen(true);
  };

  const handleClickMopen = (e) => {
    setMopen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFopen(false);
    setMopen(false);
  };

  // const handleBatch = (ele) => {
  //   for (let i = 0; i < allProduct.length; i++) {
  //     if (allProduct[i].batch_no === ele.target.value) {
  //       setProduct(allProduct[i]);
  //     }
  //   }
  // };

  const openBatchModal = (batch) => {
    setShowBatchModal(true);

    if (batch && batchNoVerify !== "verified") {
      setTimeout(() => {
        inpRef.current.focus();
      }, 200);
    }
  };

  const checkUniqueId = (e, uid, id) => {
    let tempUID = [...uniqueId];
    let uidIndx = tempUID.findIndex((ele) => ele.uid === uid);

    if (e.keyCode !== 9 && e.keyCode !== 8) {
      if (id) {
        if (uidIndx >= 0) {
          tempUID[uidIndx].id = id.toUpperCase();
        } else {
          tempUID.push({ uid: uid, id: id.toUpperCase() });
        }

        if (uid === "uid1") {
          inpRef2.current.focus();
        } else if (uid === "uid2") {
          inpRef3.current.focus();
        } else if (uid === "uid3") {
          inpRef4.current.focus();
        }else if (product_name==="cefaris" && uid === "uid4") {
          inpRef5.current.focus();
        }

        setUniqueId(tempUID);
      }
    } else if (e.keyCode === 8) {
      if (backCount + 1 === 2) {
        if (uid === "uid2") {
          inpRef.current.focus();
        } else if (uid === "uid3") {
          inpRef2.current.focus();
        } else if (uid === "uid4") {
          inpRef3.current.focus();
        }else if (uid === "uid5") {
          inpRef4.current.focus();
        }

        if (uidIndx !== -1 && !id) {
          tempUID.splice(uidIndx, 1);
          setUniqueId(tempUID);
        }
        setBackCount(0);
      } else {
        setBackCount((prev) => prev + 1);
      }
    }
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        // due to old react router packages navigate is not supported here, implemented manual redirection for this rare use case
        if (product_name === "bfolin") {
          window.location.href = "https://carin.life/products/bforcarin";
        }
        setProduct(product);
        const response = await axios.get(`${process.env.REACT_APP_API}/products/${product_name}`);

        let tempList = response.data[0];
        if (tempList.batchList) {
          setBatchFiles(tempList.batchList);
        }

        // setAllProduct(response.data);
        setProduct(response.data[0]);
        setLoading(false);
      } catch (e) {
        setProduct(product);
        setLoading(false);
        history.push("/404");
      }
    };

    fetchProduct();

    const handler = (e) => setMatches(e.matches);
    mediaMatch.addEventListener("clicl", handler);
    return () => mediaMatch.removeEventListener("click", handler);
  }, []);

  useEffect(() => {
    let checkStringLength=4;
    if(product_name==="cefaris"){
      checkStringLength=5
    }
    if (uniqueId.length === checkStringLength) {
      setLoading(true);

      for (let i = 0; i < batchFiles.length; i++) {
        let checkBatchNo = batchFiles[i].batchId.toUpperCase().split("");
        let condition=uniqueId[0].id === checkBatchNo[0] && uniqueId[1].id === checkBatchNo[1] && uniqueId[2].id === checkBatchNo[checkBatchNo.length - 2] && uniqueId[3].id === checkBatchNo[checkBatchNo.length - 1]
        if(product_name==="cefaris"){
          condition=uniqueId[0].id === checkBatchNo[0] && uniqueId[1].id === checkBatchNo[1] && uniqueId[2].id === checkBatchNo[checkBatchNo.length - 3] && uniqueId[3].id === checkBatchNo[checkBatchNo.length - 2] && uniqueId[4].id === checkBatchNo[checkBatchNo.length - 1]
        }
        if (condition) {
          setLoading(false);
          setVerifiedBatch(checkBatchNo);
          setBatchFile(batchFiles[i]);
          setBatchNoVerify("verified");
          break;
        } else if (i === batchFiles.length - 1) {
          setLoading(false);
          setBatchNoVerify("failed");
        }
      }
    }
  }, [uniqueId]);

  useEffect(() => {
    setTimeout(() => {
      if (batchNoVerify === "verified") {
        setUniqueId([]);
        setOpenBatchFile(true);
      } else if (batchNoVerify === "failed") {
        setUniqueId([]);
        setBatchNoVerify("");
      }
    }, [5000]);
  }, [batchNoVerify]);

  useEffect(() => {
    if (showBatchModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [showBatchModal]);

  return (
    <div>
      {loading ? (
        <div class="preloader">
          <div class="preloader_image"></div>
        </div>
      ) : (
        <>
          <section className="ls section_padding_bottom_30 columns_padding_25">
            <div className="container">
              <div className="product-pg-img-cntr">
                <div className="prod-sub-img-cntr">
                  <img src={product.img} useMap="#image-map" style={{ zIndex: 0 }} alt="product" />

                  {/* <div> */}
                  <div className={matches ? "indications-true Indications" : "indications-false Indications"} style={{ cursor: "pointer" }} alt="indications" id="indications" onClick={handleClickOpen}></div>
                  <div className={matches ? "dosage-true Dosage" : "dosage-false Dosage"} alt="dosage" id="dosage" style={{ cursor: "pointer" }} onClick={handleClickOpen}></div>
                  <div className={matches ? "action-true Mode of Action" : "action-false Mode of Action"} alt="action" id="mode_action" style={{ cursor: "pointer" }} onClick={handleClickOpen}></div>
                  <div className={matches ? "FAQ-true FAQs" : "FAQ-false FAQs"} alt="FAQ" style={{ cursor: "pointer" }} id="FAQ_ques" onClick={handleClickFopen}></div>
                  <div className={matches ? "side_effects-true Side Effects" : "side_effects-false Side Effects"} alt="side_effects" id="side_effects" style={{ cursor: "pointer" }} onClick={handleClickOpen}></div>
                  <div className={matches ? "interaction-true Interaction" : "interaction-false Interaction"} alt="interaction" id="interaction" style={{ cursor: "pointer" }} onClick={handleClickOpen}></div>
                  <div className={matches ? "safety_advice-true Safety Advice" : "safety_advice-false Safety Advice"} alt="safety_advice" id="safety" style={{ cursor: "pointer" }} onClick={handleClickOpen}></div>
                  {/* </div> */}
                </div>
                {open && (
                  <div className="alignment-center">
                    <br />
                    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" fullWidth open={open}>
                      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        <span style={{ fontSize: "18px" }}>{title}</span>
                      </DialogTitle>
                      <DialogContent dividers>
                        <Typography gutterBottom>
                          {product[tab] !== "" && product[tab] !== null ? (
                            product[tab].split("&").length !== 1 ? (
                              <ul style={{ marginBottom: "5px" }} className="list2 color2 checklist grey">
                                {product[tab].split("&").map(function (point, idx) {
                                  return (
                                    <li className="carin_products" key={idx}>
                                      {" "}
                                      {point.trim()}{" "}
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : (
                              <p className="carin_products">{product[tab].trim()}</p>
                            )
                          ) : (
                            <p className="carin_products">Loading ...</p>
                          )}
                        </Typography>
                      </DialogContent>
                      <DialogActions></DialogActions>
                    </Dialog>
                  </div>
                )}
                {fopen && (
                  <div style={{ textAlign: "center" }}>
                    <br />
                    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" scroll={scroll} fullWidth open={fopen}>
                      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        <span style={{ fontSize: "18px" }}>{title}</span>
                      </DialogTitle>
                      <DialogContent dividers>
                        <Typography gutterBottom>
                          {product[tab] !== "" && product[tab] !== null ? (
                            product[tab].split("&").map(function (point, idx) {
                              return (
                                <div>
                                  <p key={idx} className="carin_products txt_bold">
                                    {" "}
                                    Q. {point.trim()}{" "}
                                  </p>{" "}
                                  <p key={idx + product[tab].split("&").length} className="carin_products">
                                    {" "}
                                    A. {product["FAQ_ans"].split("&")[idx].trim()}
                                  </p>
                                  <br />
                                </div>
                              );
                            })
                          ) : (
                            <p className="carin_products">Loading ...</p>
                          )}
                        </Typography>
                      </DialogContent>
                      <DialogActions></DialogActions>
                    </Dialog>
                  </div>
                )}
              </div>

              <div className="row">
                <div className="txt_bold color-black select-batch">
                  <button onClick={() => openBatchModal(selectedBatchFile)}>{!selectedBatchFile ? "Verify Batch Number" : `BATCH NUMBER: ${selectedBatchFile.batchId}`}</button>
                </div>
              </div>

              <div className="row" style={{ padding: "20px 0 12px" }}>
                <Grid container className={classes.root} spacing={1}>
                  <Grid item xs={12}>
                    <Grid container justify="center" spacing={0}>
                      {selectedBatchFile && (
                        <Grid style={{ textAlign: "center" }} key={1} item onClick={() => openBatchModal(selectedBatchFile)}>
                          <div className={`${classes.paper} batch-img`}>
                            <img src="/img/reports.svg" alt="option" />
                          </div>
                          <div className="product-details">
                            Batch
                            <br />
                            Certificate
                          </div>
                        </Grid>
                      )}
                      {product.patient_info_leaflets !== "" && product.patient_info_leaflets !== null && (
                        <a target="_blank" rel="noopener noreferrer" href={`/pdf/patient_info_leaflets/${product.patient_info_leaflets}.pdf`} alt="work">
                          <Grid style={{ textAlign: "center" }} key={2} item>
                            <div className={classes.paper}>
                              <img src="/img/leaflet.svg" alt="option" />
                            </div>
                            <div className="product-details">
                              Patient Info
                              <br />
                              Leaflet
                            </div>
                          </Grid>
                        </a>
                      )}
                      {product.manufactured_by !== "" && product.manufactured_by !== null && (
                        <Grid onClick={handleClickMopen} style={{ textAlign: "center" }} key={3} item>
                          <div className={`${classes.paper} batch-img`}>
                            <img src="/img/manufactured.svg" alt="option" />
                          </div>
                          <div className="product-details">
                            Manufactured
                            <br />
                            By
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                {mopen && (
                  <div style={{ textAlign: "center" }}>
                    <br />
                    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" scroll={scroll} fullWidth open={mopen}>
                      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        <span style={{ fontSize: "16px" }}>Manufactured By</span>
                      </DialogTitle>
                      <DialogContent dividers>
                        <Typography gutterBottom>
                          {product.manufactured_by !== "" && product.manufactured_by !== null && (
                            <div>
                              <div className="txt_bold line-height--20" style={{ marginBottom: "5px", fontSize: "14px" }}>
                                {product.manufactured_by.split("?")[0].split("^")[0]}{" "}
                                {product.manufactured_by.split("?")[0].split("^").length === 2 && (
                                  <p
                                    className="line-height--16"
                                    style={{
                                      fontSize: "12px",
                                      marginBottom: "2px",
                                    }}>
                                    {product.manufactured_by.split("?")[0].split("^")[1]}
                                  </p>
                                )}{" "}
                              </div>{" "}
                              <p className="line-height--16" style={{ fontSize: "12px", marginBottom: "15px" }}>
                                {product.manufactured_by.split("?")[1]}
                              </p>
                            </div>
                          )}
                        </Typography>
                      </DialogContent>
                      <DialogActions></DialogActions>
                    </Dialog>
                  </div>
                )}
              </div>

              <div className="row">
                <div className="medium alignment-center" style={{ marginTop: "2rem" }}>
                  <span style={{ marginBottom: 0, fontSize: "14px", color: "black" }} className="line-height--14">
                    Disclaimer:
                  </span>
                  <p className="line-height--14" style={{ fontSize: "12px" }}>
                    Please consult your physician for personalized medical advice.
                  </p>
                </div>
              </div>

              {/* Batch Certificate Modal */}
              {showBatchModal && (
                <div className="modal-cntr">
                  <div className="batch-cntr" style={{ height: product.batchList === null || (openBatchFile && selectedBatchFile && !selectedBatchFile.batchFile) ? "150px" : openBatchFile || (batchNoVerify && openBatchFile) ? "" : "350px" }}>
                    <div className="batch-header">
                      <h3>Batch Certificate</h3>
                      {((!batchNoVerify && !openBatchFile) || (batchNoVerify === "verified" && openBatchFile)) && <p onClick={() => setShowBatchModal(false)}>🆇</p>}
                    </div>

                    {product.batchList === null ? (
                      <h5>Batch Certificate is not available</h5>
                    ) : (
                      <>
                        {!batchNoVerify && !openBatchFile && (
                          <div className="div-cntr" style={{ height: "100%" }}>
                            <p className="uid-info">
                              Thank you for scanning Health-scan of <b>{product.brand_name}</b>.
                            </p>
                            <p className="uid-info">ⓘ To verify the batch number, please enter the first two and the last {product_name==='cefaris'?'three':'two'} digits of the batch number.</p>

                            <div className="uniqueId-cntr">
                              <input autoFocus ref={inpRef} type="text" name="uid1" maxLength={1} onKeyUp={(e) => checkUniqueId(e, e.target.name, e.target.value)} />
                              <input type="text" ref={inpRef2} name="uid2" maxLength={1} onKeyUp={(e) => checkUniqueId(e, e.target.name, e.target.value)} />
                              {Array.from(new Array(product.batchList[0].batchId.length - 4), (el, indx) => (
                                <p>X</p>
                              ))}
                              <input type="text" ref={inpRef3} name="uid3" maxLength={1} onKeyUp={(e) => checkUniqueId(e, e.target.name, e.target.value)} />
                              <input type="text" ref={inpRef4} name="uid4" maxLength={1} onKeyUp={(e) => checkUniqueId(e, e.target.name, e.target.value)} />
                              {
                                product_name==="cefaris" && <input type="text" ref={inpRef5} name="uid5" maxLength={1} onKeyUp={(e) => checkUniqueId(e, e.target.name, e.target.value)} />

                              }

                            </div>

                            <p className="uid-sample">
                              <span>eg:</span>
                              <b>B</b>
                              <b>S</b>35<b>2</b>
                              <b>1</b>
                            </p>

                            <p className="uid-power">Powered by Carin</p>
                          </div>
                        )}

                        {batchNoVerify && !openBatchFile && (
                          <div className="div-cntr" style={{ height: "100%" }}>
                            {batchNoVerify === "verified" && (
                              <>
                                <Lottie animationData={VerifiedAnim} play style={{ height: "150px" }} />
                                <p className="ver-pass">
                                  Batch Certificate No. <span>{verifiedBatch}</span> is verified
                                </p>
                              </>
                            )}

                            {batchNoVerify === "failed" && (
                              <>
                                <Lottie animationData={FailedAnim} play style={{ height: "150px" }} />
                                <p className="ver-fail">Invalid batch number, please enter a valid batch number.</p>
                                <p className="uid-info">
                                  <i>To report any suspicious batch, please contact 83900 8888 or email at support@carin.in</i>
                                </p>
                              </>
                            )}
                          </div>
                        )}

                        {openBatchFile && !selectedBatchFile.batchFile && <h5>Batch Certificate is coming soon</h5>}

                        {openBatchFile && selectedBatchFile.batchFile && (
                          <>
                            <div className="batch-sub-cntr">
                              <PDFViewer batchUrl={selectedBatchFile.batchFile} />
                            </div>

                            <div className="batch-cta-cntr">
                              <button onClick={() => setShowBatchModal(false)}>Show {product.brand_name} Details</button>
                              <button onClick={() => window.open(`${process.env.REACT_APP_QA_REPORT}/${selectedBatchFile.batchFile}`, "_blank")}>Download Batch Certificate</button>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </section>

          <section className="cs logo-gradient" style={{ padding: "25px 0 90px" }}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">
                  <div className="owl-carousel partners-carousel" data-responsive-lg="9" data-responsive-md="6" data-responsive-sm="4" data-responsive-xs="2" data-nav="true" data-dots="false" data-center="true" data-loop="true" style={{ textAlign: "center" }}>
                    {FooterProducts.map((prod, index) => {
                      return (
                        <a key={"footer-products-" + index} href="#!">
                          <img style={{ height: prod.style ? prod.style.height : "" }} alt="product" src={prod.src} />
                        </a>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <a target="_blank" rel="noopener noreferrer" href={`https://wa.me/message/S2BZUWA3WW4MC1`}>
                <Fab aria-label="add" className="fabButton">
                  <img src={WhatsappIcon} alt="whatsapp icon" />
                </Fab>
              </a>

              <div className={classes.grow} style={{ marginTop: "1.5rem" }}>
                SET DIGITAL REMINDER
              </div>
            </Toolbar>
          </AppBar>
        </>
      )}
    </div>
  );
}
