import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
        textAlign: "center"
    },
    button: {
        fontSize: "14px"
    },
    card: {
        justifyContent: "center"
    }
});

export default function AdminHome() {

    const classes = useStyles();

    return (
        <div className="container">
            <div className="row">
                <div className="col-sm-4 card-admin">
                    <Card className={classes.root}>
                        <CardActionArea>
                            <CardContent>
                            <Typography gutterBottom variant="h3" component="h2">
                                Contact Us Form
                            </Typography>
                            <hr /><br /><br />
                            <Typography variant="h5" color="textSecondary" component="p">
                                Details of people who have filled the contact us form.
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <hr />
                        <CardActions className={classes.card}>
                            <Button className={classes.button} style={{marginRight: "10px"}} variant="contained" color="primary" href="/admin/contacts/all">All the people</Button>
                            <Button className={classes.button} variant="contained" color="primary" href="/admin/contacts/to_be_contacted">To Be Contacted</Button>
                        </CardActions>
                    </Card>
                </div>
                <div className="col-sm-4 card-admin">
                    <Card className={classes.root}>
                        <CardActionArea>
                            <CardContent>
                            <Typography gutterBottom variant="h3" component="h2">
                                Careers Form
                            </Typography>
                            <hr /><br /><br />
                            <Typography variant="h5" color="textSecondary" component="p">
                                Details of people who have filled the careers form.
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <hr />
                        <CardActions className={classes.card}>
                            <Button className={classes.button} style={{marginRight: "10px"}} variant="contained" color="primary" href="/admin/careers/all">All the people</Button>
                            <Button className={classes.button} variant="contained" color="primary" href="/admin/careers/to_be_contacted">To Be Contacted</Button>
                        </CardActions>
                    </Card>
                </div>
                <div className="col-sm-4 card-admin">
                    <Card className={classes.root}>
                        <CardActionArea>
                            <CardContent>
                            <Typography gutterBottom variant="h3" component="h2">
                                Blogs
                            </Typography>
                            <hr /><br /><br />
                            <Typography variant="h5" color="textSecondary" component="p">
                                Find here all the blogs, where you can add new ones or edit them.
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <hr />
                        <CardActions className={classes.card}>
                            <Button className={classes.button} variant="contained" color="primary" href="/admin/blogs/all">All the blogs</Button>
                        </CardActions>
                    </Card>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4 card-admin">
                    <Card className={classes.root}>
                        <CardActionArea>
                            <CardContent>
                            <Typography gutterBottom variant="h3" component="h2">
                                Offerings
                            </Typography>
                            <hr /><br /><br />
                            <Typography variant="h5" color="textSecondary" component="p">
                                Find here all the offerings, where you can add new ones or delete them.
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <hr />
                        <CardActions className={classes.card}>
                            <Button className={classes.button} variant="contained" color="primary" href="/admin/offerings/all">All the offerings</Button>
                        </CardActions>
                    </Card>
                </div>
                <div className="col-sm-4 card-admin">
                    <Card className={classes.root}>
                        <CardActionArea>
                            <CardContent>
                            <Typography gutterBottom variant="h3" component="h2">
                                Products
                            </Typography>
                            <hr /><br /><br />
                            <Typography variant="h5" color="textSecondary" component="p">
                                Find here all the products showed after scanning the qr code, where you can add new ones or edit them.
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <hr />
                        <CardActions className={classes.card}>
                            <Button className={classes.button} variant="contained" color="primary" href="/admin/products/all">All the products</Button>
                        </CardActions>
                    </Card>
                </div>
                <div className="col-sm-4 card-admin">
                    <Card className={classes.root}>
                        <CardActionArea>
                            <CardContent>
                            <Typography gutterBottom variant="h3" component="h2">
                                Job openings
                            </Typography>
                            <hr /><br /><br />
                            <Typography variant="h5" color="textSecondary" component="p">
                                Find here all the available job openings, where you can add new ones or delete them.
                            </Typography>
                            </CardContent>
                        </CardActionArea>
                        <hr />
                        <CardActions className={classes.card}>
                            <Button className={classes.button} variant="contained" color="primary" href="/admin/roles/all">All the openings</Button>
                        </CardActions>
                    </Card>
                </div>
            </div>
        </div>
    );
}