import React from "react";
import "./Sitemap.css";
import SitePages from "./SitePages";

const Sitemap = () => {
  return (
    <div className="sitemap-wrapper container">
      <h2 className="">SITEMAP</h2>

      <div className="sitemap-container1">
        {SitePages.map((page, index) => {
          return (
            !page.subPages && (
              <ul className="sitemap-pages" key={index + page.pageName}>
                <li>
                  <a
                    href={page.pageUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h5>{page.pageName}</h5>
                  </a>

                  {/* {page.subPages && (
                  <div className="sitemap-subPages-container">
                    {page.subPages.map((subPage, sp_index) => {
                      return (
                        <ul
                          className="sitemap-subPages"
                          key={sp_index + subPage}
                        >
                          <li>
                            <a
                              href={subPage.subPageUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h6>{subPage.subPageName}</h6>
                            </a>

                            {subPage.products &&
                              subPage.products.map((product, p_index) => {
                                return (
                                  <ul
                                    className="sitemap-products"
                                    key={p_index + product}
                                  >
                                    <li>
                                      <a
                                        href={product.productUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {product.productName}
                                      </a>
                                    </li>
                                  </ul>
                                );
                              })}
                          </li>
                        </ul>
                      );
                    })}
                  </div>
                )} */}
                </li>
              </ul>
            )
          );
        })}
        {/* <ul className="sitemap-pages">
          <li>
            <a
              href="https://www.carin.life/offerings"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h5>Our Offerings</h5>
            </a>

            <ul className="sitemap-sub-pages">
              <li>
                <a
                  href="https://www.carin.life/offerings/gynaecology"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h6>Gynaecology</h6>
                </a>

                <ul className="sitemap-products">
                  <li>
                    <a
                      href="https://carin.life/gynaecology/clomarin"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Clomarin
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul> */}
      </div>

      <div className="sitemap-container2">
        {SitePages.map((page, index) => {
          return (
            page.subPages && (
              <ul className="sitemap-pages" key={index + page.pageName}>
                <li>
                  <a
                    href={page.pageUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h5>{page.pageName}</h5>
                  </a>

                  {page.subPages && (
                    <div className="sitemap-subPages-container">
                      {page.subPages.map((subPage, sp_index) => {
                        return (
                          <ul
                            className="sitemap-subPages"
                            key={sp_index + subPage}
                          >
                            <li>
                              <a
                                href={subPage.subPageUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <h6>{subPage.subPageName}</h6>
                              </a>

                              {subPage.products &&
                                subPage.products.map((product, p_index) => {
                                  return (
                                    <ul
                                      className="sitemap-products"
                                      key={p_index + product}
                                    >
                                      <li>
                                        <a
                                          href={product.productUrl}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {product.productName}
                                        </a>
                                      </li>
                                    </ul>
                                  );
                                })}
                            </li>
                          </ul>
                        );
                      })}
                    </div>
                  )}
                </li>
              </ul>
            )
          );
        })}
        {/* <ul className="sitemap-pages">
          <li>
            <a
              href="https://www.carin.life/offerings"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h5>Our Offerings</h5>
            </a>

            <ul className="sitemap-sub-pages">
              <li>
                <a
                  href="https://www.carin.life/offerings/gynaecology"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h6>Gynaecology</h6>
                </a>

                <ul className="sitemap-products">
                  <li>
                    <a
                      href="https://carin.life/gynaecology/clomarin"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Clomarin
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul> */}
      </div>
    </div>
  );
};

export default Sitemap;
