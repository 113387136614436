import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
// import {  useLocation } from "react-router-dom";
// import firebase from "firebase";
import AdminNavbar from "./Admin/AdminNavbar";
import { checkAuth } from "../Utility/ApiService";

export default function PrivateRoute({ component: Component, ...rest }) {
  const [flag, setFlag] = React.useState(true);
  const [loading, setLoading] = useState(true);
  // const location = useLocation();

  React.useEffect(() => {
    setLoading(true);
    let authData = JSON.parse(localStorage.getItem("authData"));
    if (authData) {
      checkAuth(localStorage.getItem("mobileNo"), authData.jwtToken)
        .then((res) => {
          setLoading(false);
          if (res.data.data.user.admin) {
            setFlag(true);
          } else {
            setFlag(false);
            window.alert("Access Denied");
          }
        })
        .catch((error) => {
          setLoading(false);
          setFlag(false);
          window.alert("Something went wrong, Please try after sometime");
        });
    } else {
      setLoading(false);
      setFlag(false);
    }
  }, []);

  return (
    <>
      {!loading && (
        <Route
          {...rest}
          render={(props) => {
            return !flag ? (
              <Redirect to="/admin/login" />
            ) : (
              <div>
                {" "}
                <AdminNavbar />
                <Component {...props} />{" "}
              </div>
            );
          }}
        ></Route>
      )}
    </>
  );
}
