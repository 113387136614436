import React, { useEffect, useState } from 'react';
import axios from 'axios';
import BlogsIntro from '../Blogs/BlogsIntro';

export default function Home() {

    const [ blogs, setBlogs ] =useState([]);

	useEffect(() => {
		window.scrollTo(0, 0)

        const fetchBlogs = async () => {
            try {
                setBlogs(blogs);
                const response = await axios.get(`${process.env.REACT_APP_API}/blogs`);
                console.log(response.data);
                setBlogs(response.data);
            } 
            catch (e) {
                console.log(e);
                setBlogs(blogs);
            }
        };

        fetchBlogs();

	}, [])

    return (
        <div>
            <section className="page_breadcrumbs ds color parallax section_padding_bottom_75">
                <div className="starting">
                    <img src={process.env.REACT_APP_IMAGES+ "/self-care.jpg"} alt="" />
                </div>	
            </section>
            <br />			
            <section id="features" className="ls section_padding_top_75 section_padding_bottom_100 columns_margin_bottom_30">
                <div className="container widget widget_recent_posts">
                    <h2 className="section_header text-center">Our Blogs</h2>
                    {
                        blogs.length === 0 ? <p style={{textAlign: "center"}}>Loading...</p> :
                        blogs.map(blog => {
                            return <BlogsIntro data = {blog} />
                        })
                    }
                </div>
            </section>
        </div>
    )
}