import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export default function OfferingEdit (){

    const [ loading, setLoading ] = useState(false);
    const [ inputs, setInputs ] = useState({});

	useEffect(() => {
		window.scrollTo(0, 0);

	}, [])

    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));

    const editThis = async (e) => {
        e.preventDefault();

        var answer = window.confirm("Are you sure?");

        if(answer) {
            await axios.post(`${process.env.REACT_APP_API}/products/add`, {product: inputs})
            .then((res) => {
                console.log(res);
                setLoading(true);
            })
        }
        else {
            return;
        }

    }

    return (
        <div className="container">
            <Button variant="contained" startIcon={<ArrowBackIcon />} style={{fontSize: "16px", backgroundColor: "orange"}} href="/admin/products/all"> Go Back</Button>    
            <h3 className="logo-color1" style={{marginTop:'25px', marginBottom:"15px"}}>{ inputs.brand_name }</h3>
            <form method="POST" className="form-horizontal" id="offering-edit">
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="name">URL Endpoint name:</label>
                    </div>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" onChange={handleChange} value={ inputs.name } id="name" placeholder="Enter the name for URL endpoint" name="name" />
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="brand_name">Brand Name:</label>
                    </div>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" onChange={handleChange} value={ inputs.brand_name } id="brand_name" placeholder="Enter the brand name" name="brand_name" />
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="img">Public link for image:</label>
                    </div>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" onChange={handleChange} value={ inputs.img } id="img" placeholder="Enter the img link" name="img" />
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="batch_no">Batch Number:</label>
                    </div>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" onChange={handleChange} value={ inputs.batch_no } id="batch_no" placeholder="Enter the batch number" name="batch_no" />
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="manufactured_by">Manufactured By:</label>
                    </div>
                    <div className="col-sm-10">
                        <textarea rows="3" type="text" className="form-control" id="manufactured_by" onChange={handleChange} value={ inputs.manufactured_by } placeholder="Manufacturer's details" name="manufactured_by"></textarea>
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="offering">Offering:</label>
                    </div>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" onChange={handleChange} value={ inputs.offering } id="offering" placeholder="Enter the offering" name="offering" />
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="mode_action">Mode of action:</label>
                    </div>
                    <div className="col-sm-10">
                        <textarea rows="5" type="text" className="form-control" id="mode_action" onChange={handleChange} value={ inputs.mode_action } placeholder="Mode of Action" name="mode_action"></textarea>
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="indications">Indication:</label>
                    </div>
                    <div className="col-sm-10">
                        <textarea rows="5" type="text" className="form-control" id="indications" onChange={handleChange} placeholder="Indications" value={ inputs.indications } name="indications"></textarea>
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="dosage">Dosage:</label>
                    </div>
                    <div className="col-sm-10">
                        <textarea rows="5" type="text" className="form-control" id="dosage" onChange={handleChange} placeholder="Dosage" value={ inputs.dosage } name="dosage"></textarea>
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="safety">Safety Advice:</label>
                    </div>
                    <div className="col-sm-10">
                        <textarea rows="10" type="text" className="form-control" id="safety" onChange={handleChange} placeholder="Safety advice" value={ inputs.safety } name="safety"></textarea>
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="side_effects">Side Effects:</label>
                    </div>
                    <div className="col-sm-10">
                        <textarea rows="5" type="text" className="form-control" id="side_effects" onChange={handleChange} placeholder="Side Effects" value={ inputs.side_effects } name="side_effects"></textarea>
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="interaction">Interaction:</label>
                    </div>
                    <div className="col-sm-10">
                        <textarea rows="5" type="text" className="form-control" id="interaction" onChange={handleChange} placeholder="interaction" value={ inputs.interaction } name="interaction"></textarea>
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="FAQ_ques">FAQ Questions:</label>
                    </div>
                    <div className="col-sm-10">
                        <textarea rows="5" type="text" className="form-control" id="FAQ_ques" onChange={handleChange} placeholder="Questions for FAQ" value={ inputs.FAQ_ques } name="FAQ_ques"></textarea>
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="FAQ_ans">FAQ Answers:</label>
                    </div>
                    <div className="col-sm-10">
                        <textarea rows="5" type="text" className="form-control" id="FAQ_ans" onChange={handleChange} placeholder="Answers for FAQ" value={ inputs.FAQ_ans } name="FAQ_ans"></textarea>
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="vid">Testimonials:</label>
                    </div>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" onChange={handleChange} value={ inputs.vid } id="vid" placeholder="Enter the testimonials" name="vid" />
                    </div>
                </div>
                {
                    loading && <p className="edit-success" style={{textAlign: "center", fontWeight: "bold"}}>The product is added!</p>
                }
                <div className="form-group" style={{paddingTop: "15px"}}>        
                    <div className="col-sm-offset-4 col-sm-4" style={{textAlign: "center"}}>
                        <Button variant="outlined" color="secondary" style={{fontSize: "16px"}} onClick={editThis}>Submit</Button>    
                    </div>
                </div>
            </form>
        </div>
    )
}