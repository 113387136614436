import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
// import firebase from "firebase/app";
import "firebase/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    minHeight: 75,
    alignItems: "flex-start",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    alignItems: "flex-end",
  },
}));

export default function AdminNavbar() {
  const mediaMatch = window.matchMedia("(min-width: 825px)");
  const [matches, setMatches] = useState(mediaMatch.matches);

  const history = useHistory();

  const sessionLogout = async (e) => {
    localStorage.clear();
    history.push("/admin/login");
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const handler = (e) => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  }, []);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar
        style={
          matches
            ? { backgroundColor: "lightgray", padding: "25px 75px 0" }
            : { backgroundColor: "lightgray" }
        }
        position="static"
      >
        <Toolbar className={classes.toolbar}>
          {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
              <MenuIcon />
          </IconButton> */}
          <Typography variant="h3" className={classes.title}>
            <a href="/admin/home">
              <div className="display_table_cell">
                <img
                  className="carin_logo"
                  src="https://d214gcurai71bm.cloudfront.net/carin-images/carin.svg"
                  alt=""
                />
              </div>
            </a>
          </Typography>
          <Typography variant="h3" className={classes.title}>
            <b>Admin Dashboard</b>
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            style={
              matches
                ? { fontSize: "20px", marginLeft: "15px" }
                : { fontSize: "14px", marginLeft: "8px", marginTop: "15px" }
            }
            href="/admin/home"
          >
            Home
          </Button>
          <Button
            style={
              matches
                ? { fontSize: "20px", marginLeft: "15px" }
                : { fontSize: "14px", marginLeft: "8px", marginTop: "15px" }
            }
            variant="contained"
            color="primary"
            onClick={sessionLogout}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <br />
      <br />
    </div>
  );
}
