import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
    borderRadius: 8,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 42.5,
    width: 42.5,
    textAlign: "center",
    margin: "0 28px 5px",
  },
  control: {
    padding: theme.spacing(2),
  },
  appBar: {
    top: "auto",
    bottom: 0,
    background: "#4b4495",
    padding: "7px 0 0",
  },
  grow: {
    flexGrow: 1,
    textAlign: "center",
    fontSize: "14px",
    fontWeight: "bold",
    color: "white",
  },
}));
