import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';

export default function Contact() {

	const [ name, setName ] = useState("");
	const [ email, setEmail ] = useState("");
	const [ subject, setSubject ] = useState("");
	const [ message, setMessage ] = useState("");

	const handleSubmit = (e) => {
		e.preventDefault();

		if(name === "" || email === "" || subject === "" || message === "")
		{
			alert("All fields are required!");
			return;
		}

		const to_contact = {
			name,
			email,
			subject,
			message
		};

		axios
		.post(`${process.env.REACT_APP_API}/contacts`, to_contact)
		.then((res) => {
			console.log(res);
		})
		.catch(err => {
			console.log(err);
		});

		alert("Thanks for Contacting us! We Will get in touch with you ASAP...");

		setName("");
		setEmail("");
		setSubject("");
		setMessage("");
	}

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

    return (
        <div>
		 <Helmet>
         <title>CARIN serves a wide range of products across therapies and intends to add many more to its portfolio.</title>
        <meta name="title" content="CARIN serves a wide range of products across therapies and intends to add many more to its portfolio." />
        <meta name="description" content="We at CARIN are advocators of Science and using it to advance health is our calling.
									We are an assistive, Digital-First Healthcare brand. We believe that technology can be a great leveler and make Quality Healthcare much more accessible. We strive for a better future, for all." />
        <link rel="canonical" href="https://www.carin.life/contact" />
        </Helmet>
            <section className="ls section_padding_top_50 section_padding_bottom_75">
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							<h2 className="logo-color1">Contact Us</h2>
						</div>
					</div>
					<div className="row topmargin_40 why">
						<div style={{paddingBottom: "30px", margin: "auto"}} className="col-sm-6 to_animate" data-animation="pullDown">
							<div className="teaser text-center">
								<div className="teaser_icon highlight2 size_normal"> <i className="rt-icon2-location2"></i> </div>
								<p>New 79/2, <br /> New Timber Yard Layout, <br /> Bangalore, Karnataka 560026</p>
							</div>
						</div>
						<div style={{paddingBottom: "30px", margin: "auto"}} className="col-sm-6 to_animate" data-animation="pullDown">
							<div className="teaser text-center">
								<div className="teaser_icon highlight2 size_normal"> <a href="mailto:connect@carin.life"><i className="rt-icon2-mail"></i></a> </div>
								<p><a className="media-body highlight2links" href="mailto:connect@carin.life">connect@carin.life</a></p>
							</div>
						</div>
					</div>
					<div className="row topmargin_40">
						<div className="col-sm-12 to_animate">
							<form method="POST" className="contact-form columns_padding_5" name="contact" id="myForm">
								<div className="row">
									<div className="col-sm-6">
										<p className="form-group"> <label for="name">Full Name <span className="required">*</span></label> <i className="fa fa-user highlight2" aria-hidden="true"></i> <input onChange={(e)=>setName(e.target.value)} type="text" aria-required="true" size="30" value={name} name="name" id="name" className="form-control" placeholder="Full Name" /></p>
										<p className="form-group"> <label for="email">Email address<span className="required">*</span></label> <i className="fa fa-envelope highlight2" aria-hidden="true"></i> <input onChange={(e)=>setEmail(e.target.value)} type="email" aria-required="true" size="30" value={email} name="email" id="email" className="form-control" placeholder="Email Address" /></p>
										<p className="form-group"> <label for="subject">Subject<span className="required">*</span></label> <i className="fa fa-flag highlight2" aria-hidden="true"></i> <input onChange={(e)=>setSubject(e.target.value)} type="text" aria-required="true" size="30" value={subject} name="subject" id="subject" className="form-control" placeholder="Subject" /></p>
									</div>
									<div className="col-sm-6">
										<p className="contact-form-message form-group"> <label for="message">Message</label> <i className="fa fa-comment highlight2" aria-hidden="true"></i> <textarea onChange={(e)=>setMessage(e.target.value)} value={message} aria-required="true" rows="3" cols="45" name="message" id="message" className="form-control" placeholder="Message"></textarea> </p>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-12">
										<p className="contact-form-submit text-center topmargin_10"> <button onClick={handleSubmit} type="submit" id="contact_form_submit" name="contact_submit" className="theme_button wide_button color2">Send Message</button> </p>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
        </div>
    )
}
