import React from 'react';
import { Link } from 'react-router-dom';

export default function BlogsIntro(props) {

    return(
        <article className="post side-item side-md content-padding with_border rounded topmargin_30">
            <div className="row why">
                <div className="margin_auto col-md-3">
                    <div className="item-media top_rounded overflow_hidden"> <img style={{border: "1px solid #e7eaeb"}} className="blog-img" src={props.data.img} alt="" />
                        <div className="media-links"> <Link className="abs-link" to={`/blogs/${props.data.name}`}> </Link> </div>
                    </div>
                </div>
                <div className="col-md-9 margin_auto">
                    <div style={{paddingTop: "25px", paddingBottom: "10px"}} className="item-content">
                        <h4 className="entry-title"> <Link className="logo-color1" to={`/blogs/${props.data.name}`}>{props.data.title}</Link> </h4>
                        <p>{props.data.intro}<Link className="" to={`/blogs/${props.data.name}`}> Read More</Link></p>
                        <i className="logo-color2">{props.data.date_published}</i><i style={{right: "35px", position:"absolute"}} className="logo-color2">{props.data.author}</i>
                    </div>
                </div>	
            </div>
        </article>
    )
}