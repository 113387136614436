import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
// import { useStyles } from "./ProductsStyles";
import Salt from "../../assets/svg/salt.svg";
import heart from "../../assets/svg/heart.svg";
import life from "../../assets/svg/life.svg";
import safety from "../../assets/svg/safety.svg";
import alarm from "../../assets/svg/alarm.svg";
import faq from "../../assets/svg/faq.svg";
import mode from "../../assets/svg/mode.svg";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
// import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./Products.css";
import { Helmet } from "react-helmet";
import Footer from "../Landing-Page/Footer";
// import { borderBottom } from "@mui/system";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
export default function ProductPage(props) {
  // const classes = useStyles();

  let { product_name, offering } = props.match.params;

  const history = useHistory();

  const mediaMatch = window.matchMedia("(min-width: 765px)");
  const [matches, setMatches] = useState(mediaMatch.matches);

  const [product, setProduct] = useState([]);
  const [allProduct, setAllProduct] = useState([]);
  // const [tab, setTab] = useState();
  // const [title, setTitle] = useState();
  // const [vidURL, setVidURL] = useState();
  // const scroll = "paper";
  // const [vopen, setVopen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchProduct = async () => {
      try {
        setProduct(product);
        const response = await axios.get(
          `${process.env.REACT_APP_API}/products/productPage/${product_name}`
        );

        setAllProduct(response.data);
        setProduct(response.data);
        let removeNode = document.getElementById("schema");
        removeNode && removeNode.remove();
        let node = document.createElement("script");
        node.setAttribute("type", "application/ld+json");
        node.setAttribute("id", "schema");
        let faqs = [];
        response.data.FAQ_ques &&
          response.data.FAQ_ques.split("&").map((que, i) => {
            return faqs.push(`{
              "@type": "Question",
              "name":"${que}",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "${response.data.FAQ_ans.split("&")[i]}"
              }
            }`);
          });

        node.innerHTML = ` {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [${faqs}]
        }`;
        document.body.prepend(node);

        let removeNode2 = document.getElementById("schema2");
        removeNode2 && removeNode2.remove();
        let node2 = document.createElement("script");
        node2.setAttribute("type", "application/ld+json");
        node2.setAttribute("id", "schema2");
        node2.innerHTML = `
          {
            "@context": "https://schema.org/", 
            "@type": "Product", 
            "name":"${response.data.brand_name}",
            "image":"${response.data.img}",
            "description":" ${response.data.mode_action}",
            "brand": {
              "@type": "Brand",
              "name": "Carin"
            }
          }
         `;
        document.body.prepend(node2);
      } catch (e) {
        setProduct(product);
        history.push("/404");
      }
    };

    fetchProduct();
    // imageMapResize();
    const handler = (e) => setMatches(e.matches);
    mediaMatch.addEventListener("clicl", handler);
    return () => mediaMatch.removeEventListener("click", handler);
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          {product_name.toUpperCase() +
            "- Carin Healthcare | Available on Saveo"}
        </title>
        <meta
          name="title"
          content={
            product_name.toUpperCase() +
            "- Carin Healthcare | Available on Saveo"
          }
        />
        <meta
          name="description"
          content={
            product_name.toUpperCase() +
            " by Carin Healthcare Products. Read about " +
            product_name.toUpperCase() +
            " tablets Dosage, Safety Advice, Side Effect, and FAQs. Click to know more"
          }
        />
        <meta
          name="keywords"
          content={`${product_name}, Carin Healthcare, ${product_name} dosage, ${product_name} side effect`}
        />
        <link
          rel="canonical"
          href={`https://www.carin.life/${offering}/${product_name}`}
        />
      </Helmet>
      <div></div>
      <div>
        {/* <img
              src={product.img}
              useMap="#image-map"
              style={{ zIndex: 0 }}
              alt="product"
            /> */}
        <div className="product_outer_sec">
          <div className="product_nav_links  container ">
            <Link to="/offerings" className="product_nav_link">
              Our Offerings
            </Link>{" "}
            <ChevronRightIcon fontSize="large" className="nav_link_icons" />
            <Link
              to={`/offerings/${
                product.offering === "diabetics"
                  ? "anti-diabetics"
                  : product.offering === "cardiovascular"
                  ? "cardiovascular_metabolics"
                  : product.offering === "infectives"
                  ? "anti-infectives"
                  : product.offering
              }`}
              className="product_nav_link"
            >
              {product.offering &&
                product.offering.charAt(0).toUpperCase() +
                  product.offering.slice(1)}
            </Link>{" "}
            <ChevronRightIcon fontSize="large" className="nav_link_icons" />
            <span style={{ color: "#495062" }}>
              {" "}
              {product.name &&
                product.name.charAt(0).toUpperCase() + product.name.slice(1)}
            </span>
          </div>
          <div className="product_section_1 display_flex">
            <div className="product_img_div">
              <img
                src={
                  process.env.REACT_APP_IMAGES +
                  "/square/" +
                  product.productImage +
                  ".png"
                }
                className="product_img"
                alt={product.brand_name + "- Carin"}
              />
            </div>
            <div className="product_main_data">
              <div className="product_title_div">
                <h1 className="product_name entry-title">
                  {product.brand_name}
                </h1>
                <h3 className="product_salt">
                  <img src={Salt} alt="Salt" className="salt_icon" />{" "}
                  {product.salt}
                </h3>
              </div>
            </div>
          </div>
          <div className="product_section_2 container to_animate">
            <h2 className="color_pink font_2rem">
              <img
                src={mode}
                className="product_page_icons"
                alt="INDICATIONS"
              />
              &nbsp;&nbsp; <span className="sub_heads">MODE OF ACTION</span>{" "}
            </h2>
            <div className="content_list">
              <ul>
                {product.mode_action &&
                  product.mode_action.split("&").map((line) => {
                    return <li>{line}</li>;
                  })}
              </ul>
            </div>
          </div>
          <div className="product_section_2 container to_animate">
            <h2 className="color_pink font_2rem">
              <img
                src={heart}
                className="product_page_icons"
                alt="INDICATIONS"
              />
              &nbsp;&nbsp; <span className="sub_heads">INDICATIONS</span>{" "}
            </h2>
            <div className="content_list">
              <ul>
                {product.indications &&
                  product.indications.split("&").map((line) => {
                    return <li>{line}</li>;
                  })}
              </ul>
            </div>
          </div>
          <div className="product_section_2 container to_animate">
            <h2 className="color_pink font_2rem">
              <img
                src={life}
                className="product_page_icons"
                alt="DRUG INTERACTION"
              />
              &nbsp;&nbsp; <span className="sub_heads">DRUG INTERACTION</span>{" "}
            </h2>
            <div>
              <p className="content_list font_16">{product.interaction}</p>
            </div>
          </div>
          {/* <div className="product_section_2 container to_animate">
            <h2 className="color_pink font_2rem">DOSAGE </h2>
            <div>
              <p className="color_black font_16">{product.dosage}</p>
            </div>
          </div> */}
          <div className="product_section_2 container to_animate sideEffects_sec">
            <div className=" safety">
              <h2 className="color_pink font_2rem">
                <img src={safety} className="product_page_icons" alt="Safety" />
                &nbsp;&nbsp;SAFETY ADVICE
              </h2>
              <div className="content_list">
                <ul>
                  {product.safety &&
                    product.safety.split("&").map((line) => {
                      return <li>{line}</li>;
                    })}
                </ul>
              </div>
            </div>
            <div className="  side_effects">
              <div>
                <h2 className="color_pink font_2rem">
                  <img src={alarm} className="product_page_icons" alt="Alarm" />
                  &nbsp;&nbsp;SIDE EFFECTS
                </h2>
                <div className="content_list">
                  <ul>
                    {product.side_effects &&
                      product.side_effects.split("&").map((line) => {
                        return <li>{line}</li>;
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="faqs">
                      <b className="color_dark"> Q. {que}</b> <br></br>
                      <span className="color_black">
                        {" "}
                        A. {product.FAQ_ans && product.FAQ_ans.split("&")[i]}
                      </span>
                    </div> */}
          <div className="faq_parent">
            <div className="product_section_2 container to_animate ">
              <p className="faq_header">
                {" "}
                <img src={faq} alt="Faq" className="product_page_icons" /> FAQ's
              </p>

              <div>
                {product.FAQ_ques &&
                  product.FAQ_ques.split("&").map((que, i) => {
                    return (
                      <div className="faqs">
                        <Accordion className="faq_accordion">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className="faq_panel_header"
                          >
                            <h6
                              style={{ color: "#444A9C" }}
                              className="faq_que"
                            >
                              Q. {que}
                            </h6>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{ background: "#F5F6F7", padding: "10px" }}
                          >
                            <h6
                              className="faq_ans"
                              style={{
                                color: "#242831",
                                fontWeight: "lighter",
                                lineHeight: "28px",
                              }}
                            >
                              A.{" "}
                              {product.FAQ_ans && product.FAQ_ans.split("&")[i]}
                            </h6>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="cs logo-gradient" style={{ padding: "20px 0px" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div
                className="owl-carousel partners-carousel"
                data-responsive-lg="8"
                data-responsive-md="6"
                data-responsive-sm="4"
                data-responsive-xs="2"
                data-nav="true"
                data-dots="false"
                data-center="true"
                data-loop="true"
                style={{ textAlign: "center" }}
              >
                <a href="#!">
                  {" "}
                  <img
                    alt="aceparin SP"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/aceparin SP.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="aceparin"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/aceparin.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Bfolin"
                    style={{ height: "32px" }}
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Bfolin.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Calvitin D3"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Calvitin D3.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Candrin 100"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Candrin 100.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Candrin 200"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Candrin 200.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="carneo"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/carneo.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="cazit 250"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/cazit 250.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="cazit 500"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/cazit 500.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="cfixin"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/cfixin.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Clomarin"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Clomarin.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Coffrin Dx"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Coffrin Dx.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Coffrin"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Coffrin.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Etcoxin TH"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Etcoxin TH.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Fexrin 120"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Fexrin 120.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Fexrin 180"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Fexrin 180.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Folferin xt"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Folferin xt.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="glimirin M1"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/glimirin M1.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="glimirin M2"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/glimirin M2.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="glimirin MV1"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/glimirin MV1.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="glimirin MV2"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/glimirin MV2.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Lulirin"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Lulirin.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Montrin LC"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Montrin LC.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="movirin gel"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/movirin gel.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Moxyrin CV"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Moxyrin CV.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="panrin 40"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/panrin 40.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="panrin D"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/panrin D.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Podorin 200"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Podorin 200.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Progein 200"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Progein 200.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Progein 400"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Progein 400.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Raberin 20"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Raberin 20.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Raberin d"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Raberin d.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Raberin L"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Raberin L.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Rosurin 10"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Rosurin 10.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Rosurin 40"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Rosurin 40.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Rosurin 5"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Rosurin 5.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Sinarin"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Sinarin.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Tenelipt M1000"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Tenelipt M1000.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Tenelipt M500"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Tenelipt M500.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="vibin"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/vibin.svg"
                  />{" "}
                </a>
                <a href="#!">
                  {" "}
                  <img
                    alt="Vitcin D3"
                    src="https://d214gcurai71bm.cloudfront.net/carin-images/medicines/Vitcin D3.svg"
                  />{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Footer />
      {/* <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Fab aria-label="add" className="fabButton">
            <AddIcon style={{ fontSize: "30px", color: "#4b4495" }} />
          </Fab>
          <div className={classes.grow}>SET DIGITAL REMINDER</div>
        </Toolbar>
      </AppBar> */}
    </div>
  );
}
