import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useLocation } from "react-router-dom";


export default function Blog_Edit (){

    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const blog_name = splitLocation[3];

    const [ blog, setBlog ] =useState({});
    const [ loading, setLoading ] = useState(false);
    const [inputs,setInputs] = useState({});

	useEffect(() => {
		window.scrollTo(0, 0)

        const fetchBlog = async () => {
            try {
                setBlog(blog);
                const response = await axios.get(`${process.env.REACT_APP_API}/blogs/${blog_name}`);
                // console.log(response.data);
                setBlog(response.data);
                setInputs(response.data);
                setLoading(false);
            } 
            catch (e) {
                console.log(e);
                setBlog(blog);
            }
        };

        fetchBlog();

	}, [])

    const handleChange = e => setInputs(prevState => ({ ...prevState, [e.target.name]: e.target.value }));

    const editThis = async (e) => {
        e.preventDefault();

        var answer = window.confirm("Are you sure?");

        if(answer) {
            await axios.post(`${process.env.REACT_APP_API}/blogs/edit`, {blog: inputs})
            .then((res) => {
                console.log(res);
                setLoading(true);
            })
        }
        else {
            return;
        }

    }

    return (
        <div className="container">
            <Button variant="contained" startIcon={<ArrowBackIcon />} style={{fontSize: "16px", backgroundColor: "orange"}} href="/admin/blogs/all"> Go Back</Button>    
            <h3 className="logo-color1" style={{marginTop:'25px', marginBottom:"15px"}}>{ inputs.title }</h3>
            <form method="POST" className="form-horizontal" id="blog-edit">
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="date">Date Published:</label>
                    </div>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" onChange={handleChange} value={ inputs.date_published } id="date" placeholder="Enter the date" name="date_published" />
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="author">Author:</label>
                    </div>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" onChange={handleChange} value={ inputs.author } id="author" placeholder="Enter the author" name="author" />
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="name">URL Endpoint name:</label>
                    </div>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" onChange={handleChange} value={ inputs.name } id="name" placeholder="Enter the name" name="name" />
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="title">Title:</label>
                    </div>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" onChange={handleChange} value={ inputs.title } id="title" placeholder="Enter the title" name="title" />
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="image">Image link:</label>
                    </div>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" onChange={handleChange} value={ inputs.img } id="image" placeholder="Enter the image link" name="img" />
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="intro_l">Intro for landing page:</label>
                    </div>
                    <div className="col-sm-10">
                        <textarea rows="5" type="text" className="form-control" id="intro_l" onChange={handleChange} value={ inputs.intro } placeholder="Enter the intro" name="intro"></textarea>
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="intro_para">Intro Para</label>
                    </div>
                    <div className="col-sm-10">
                        <textarea rows="5" type="text" className="form-control" id="intro_para" onChange={handleChange} placeholder="Enter the intro_para" value={ inputs.intro_para } name="intro_para"></textarea>
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="titles">Titles</label>
                    </div>
                    <div className="col-sm-10">
                        <textarea rows="10" type="text" className="form-control" id="titles" onChange={handleChange} placeholder="Enter the titles" value={ inputs.titles } name="titles"></textarea>
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="content">Content</label>
                    </div>
                    <div className="col-sm-10">
                        <textarea rows="10" type="text" className="form-control" id="content" onChange={handleChange} placeholder="Enter the content" value={ inputs.content } name="content"></textarea>
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="note">Note</label>
                    </div>
                    <div className="col-sm-10">
                        <textarea rows="3" type="text" className="form-control" id="note" onChange={handleChange} placeholder="Enter the note" value={ inputs.note } name="note"></textarea>
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="references_blog">References:</label>
                    </div>
                    <div className="col-sm-10">
                        <textarea rows="5" type="text" className="form-control" id="references_blog" onChange={handleChange} value={ inputs.references_blog } placeholder="Enter the references" name="references_blog"></textarea>
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="next">Next:</label>
                    </div>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" onChange={handleChange} value={ inputs.next } id="next" placeholder="Enter the next" name="next" />
                    </div>
                </div>
                <div className="form-group">
                    <div className="control-label col-sm-2"><label for="prev">Previous:</label>
                    </div>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" onChange={handleChange} value={ inputs.prev } id="prev" placeholder="Enter the prev" name="prev" />
                    </div>
                </div>
                {
                    loading && <p className="edit-success" style={{textAlign: "center", fontWeight: "bold"}}>Your changes are saved!</p>
                }
                <div className="form-group" style={{paddingTop: "15px"}}>        
                    <div className="col-sm-offset-4 col-sm-4" style={{textAlign: "center"}}>
                        <Button variant="outlined" color="secondary" style={{fontSize: "16px"}} onClick={editThis} value={blog.name}>Submit</Button>    
                    </div>
                </div>
            </form>
        </div>
    )
}