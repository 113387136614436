import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@material-ui/core/Button";

export default function ProductsAll() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchProducts = async () => {
      try {
        setProducts(products);
        const response = await axios.get(
          `${process.env.REACT_APP_API}/products/all`
        );
        // console.log(response.data);
        setProducts(response.data);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setProducts(products);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div className="container">
      {/* <button type="button" className="btn btn-warning btn-lg" style={{fontWeight: "bold", fontSize: "large", marginRight: "100px"}}><a href="#">Add image</a></button> */}
      <Button
        variant="outlined"
        color="inherit"
        style={{
          fontSize: "18px",
          backgroundColor: "purple",
          color: "whitesmoke",
        }}
        href="/admin/products/add"
      >
        Add New Product
      </Button>
      <div className="row" style={{ width: "100%" }}>
        <table className="table table-striped styled-table col-sm-12">
          <thead>
            <tr>
              <th>Sr. No</th>
              <th>URL Endpoint name</th>
              <th>Brand Name</th>
              <th>Batch No</th>
              <th>Image Link</th>
              <th>Offering</th>
              <th>QA Reports</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <p>Loading...</p>
            ) : (
              products.map(function (product, index) {
                return (
                  <tr key={index}>
                    <td>{product.id}</td>
                    <td>{product.name}</td>
                    <td>{product.brand_name}</td>
                    <td>{product.batch_no}</td>
                    <td>{product.img}</td>
                    <td>{product.offering}</td>
                    <td>
                      {product.quality_reports &&
                      product.quality_reports !== "" ? (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${process.env.REACT_APP_QA_REPORT}/${product.quality_reports}`}
                        >
                          {product.quality_reports}
                        </a>
                      ) : (
                        "None"
                      )}
                    </td>
                    <td>
                      <Button
                        variant="outlined"
                        color="secondary"
                        style={{ fontSize: "16px" }}
                        value={product.id}
                        href={product.id + "/" + product.name}
                      >
                        Edit
                      </Button>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
