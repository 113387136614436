import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

function Para (props) {
    return <p style={{fontSize: "16px"}}>{props.para}</p>
}

function List (props) {

    return (
        <ul style={{fontSize: "16px"}} className="list2 color2 checklist grey">
            {
                props.points.split('^').map((point) => {
                    if(point.split(':').length === 1) 
                        return <li>{point}</li>
                    else return <li><b>{point.split(':')[0]}:</b>{point.split(':')[1]}</li>
                })
            }
        </ul>
    )
}

function Section(props) {
    var text = props.para.split('$');
    var idx = props.idx;
    
    return (
        <div style={{marginTop:"30px"}} className="item-meta">
            <h5 style={{fontWeight: 500}} className="highlightlinks">{props.title}</h5>
            {
                text[idx].split('#').map((sect) => {
                    if(sect.split('^').length === 1)
                        return <Para para = {sect} />
                    else return <List points = {sect} />
                })
            }
        </div>
    )
}

export default function BlogsPost(props) {

    console.log(props.match.params)

    let { blog_name } = props.match.params;

    const [ blogs, setBlogs ] = useState();
    const [ prev, setPrev ] = useState("");
    const [ next, setNext ] = useState("");

	useEffect(() => {
		window.scrollTo(0, 0)

        const fetchBlogs = async () => {
            try {
                setBlogs(blogs);
                const response = await axios.get(`${process.env.REACT_APP_API}/blogs/${blog_name}`);
                setBlogs(response.data);
                setPrev(response.data.prev);
                setNext(response.data.next);
            let removeNode=document.getElementById('schema')
             removeNode && removeNode.remove()
               let node=document.createElement('script')
                 node.setAttribute('type','application/ld+json')
                 node.setAttribute('id','schema')
                 node.innerHTML=`{
                    "@context": "https://schema.org",
                    "@type": "BlogPosting",
                    "mainEntityOfPage": {
                      "@type": "WebPage",
                      "@id": "https://www.carin.life/blogs/${response.data.name}"
                    },
                    "headline":${response.data.title},
                    "description": ${response.data.intro},
                    "image": ${response.data.img},  
                    "author": {
                      "@type": "Person",
                      "name": ${response.data.author}
                    },  
                    "publisher": {
                      "@type": "Organization",
                      "name": "Carin Life",
                      "logo": {
                        "@type": "ImageObject",
                        "url": "https://d214gcurai71bm.cloudfront.net/carin-source-files/carin-images/carin.svg"
                      }
                    },
                    "datePublished":${response.data.date_published}
                  }`
                  document.body.prepend(node)
            } 
            catch (e) {
                console.log(e);
                setBlogs(blogs);
            }
        };

        fetchBlogs();

	}, [])

    return (
        blogs !== undefined ? <div>
             
            <section className="ls section_padding_top_50 section_padding_bottom_50 columns_padding_25">
            
                <div className="container">
                    <div className="row">
                        
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <article className="single-post vertical-item content-padding big-padding with_border rounded post">
                                <div style={{border: "2px solid #e7eaeb"}} className="entry-thumbnail item-media top_rounded"> <img src={blogs.img} alt="" />
                                    {/* <div style={{backgroundColor: "#4b4495"}} className="mso-border-top-alt"> <span style={{color:"white"}} className="small-text">{blogs.date_published}</span> </div> */}
                                    {/* <!-- <div className="entry-meta-corner main_bg_color2"> <span className="date">25</span> <span className="small-text">10/17</span> </div> --> */}
                                </div>
                                <div className="item-content">
                                    <header className="entry-header">
                                        <p className="logo-color2" style={{float: "left"}}><b>{blogs.date_published}</b></p>
                                        <p style={{float: "right"}} className="logo-color2"><b>{blogs.author}</b></p>
                                        <br />
                                        <br />
                                        <h1 className="entry-title topmargin_0">{blogs.title}</h1>
                                        <div className="muted_background post-adds content-justify rounded">
                                        </div>
                                    </header>
                                    <div className="item-meta">
                                    {
                                        (blogs.intro_para !== null) && blogs.intro_para.split('#').map((para) =>
                                            <Para para={para} />
                                        )
                                    }
                                    </div>
                                    {
                                        (blogs.titles !== null) && blogs.titles.split('$').map((blog, index) => <Section title={blog} idx={index} para={blogs.content} />)
                                    }
                                    <strong class="highlightlinks">{blogs.note}</strong>
                                    {
                                        (blogs.references_blog !== null && blogs.references_blog !== "") && 
                                        <div style={{marginTop:"50px"}} className="item-meta">
                                        <div id="accordion3" className="panel-group collapse-unstyled">
                                            <div className="panel">
                                                <h4 style={{fontSize: "20px"}} className="poppins logo-color1"> <a data-toggle="collapse" data-parent="#accordion3" href="#collapse11">
                                                    References:
                                                    </a>
                                                </h4>
                                                <div id="collapse11" className="panel-collapse collapse">
                                                    <div className="panel-content">
                                                        <ol className="list1 no-bullets">
                                                        { 
                                                            blogs.references_blog.split('^').map(reference => {
                                                                if(reference.split('`').length === 1)
                                                                    return <li>{reference}</li>
                                                                else 
                                                                {
                                                                    return <li>{reference.split('`')[0]}<a href={reference.split('`')[1]} target="_blank" rel="noopener noreferrer">{reference.split('`')[1]}</a>{reference.split('`')[2]}</li>
                                                                }
                                                            })
                                                        }
                                                        </ol>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    }
                                </div>
                            </article>
                            <div className="row columns_padding_5 page-nav">
                                <div className="col-md-6">
                                {
                                    prev && <div className="with_padding text-center bg_teaser after_cover"> <img src="https://d214gcurai71bm.cloudfront.net/carin-images/images/gallery/03.jpg" alt="" />
                                        <div>
                                            <div className="highlight2 medium"> Prev </div>
                                            <h4 className="entry-title"> <Link to={`/blogs/${prev.split('$')[0]}`}>{prev.split('$')[1]}</Link> </h4>
                                        </div>
                                    </div>
                                }
                                </div>
                                <div className="col-md-6">
                                {
                                    next && <div className="with_padding text-center bg_teaser after_cover"> <img src="https://d214gcurai71bm.cloudfront.net/carin-images/images/gallery/04.jpg" alt="" />
                                        <div className="item-content">
                                            <div className="highlight2 medium"> Next </div>
                                            <h4 className="entry-title"> <Link to={`/blogs/${next.split('$')[0]}`}>{next.split('$')[1]}</Link> </h4>
                                        </div>
                                    </div>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        : <div style={{textAlign:"center"}}>Loading...</div>
    )
}