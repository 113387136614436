import React, { useEffect } from 'react';

export default function Gynaecology() {

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

    return (
        <div>
            <section className="ls section_padding_top_25 section_padding_bottom_75 columns_padding_25">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <h2 style={{textAlign: "center", marginBottom: "5px"}} className="logo-color1">Page Not Found!</h2>
                        </div>
                        
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="entry-thumbnail item-media top_rounded"> <img style={{height: "80vh", width: "auto"}} src={ process.env.REACT_APP_IMAGES + "/404.jpg"} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}