
import React from 'react';
import {Link} from 'react-scroll';

export default function OfferingsCarousel(props) {

    return (
        <div className="gallery-item rounded caro-item">
            <div className="item-media rounded overflow_hidden"> 
            {
                (props.data.img_caro !== null && props.data.img_caro !== "") ? <img src={process.env.REACT_APP_IMAGES + "/landscape/" + props.data.img_caro + ".png"} alt="product" /> : 
                <img src="https://d214gcurai71bm.cloudfront.net/carin-images/images/gallery/08.jpg" alt="product" />
            }
                <div className="media-links"> <Link to={props.data.href} offset={-100} spy={true} smooth={true} className="abs-link prettyPhoto" ></Link> </div>
                <p className="offering-titles">{props.data.name_caro}</p>
            </div>
        </div>
    );
}