import React, { useEffect } from 'react';
import {Carousel} from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Paper } from '@material-ui/core'
import LazyLoad from 'react-lazyload';
import { Helmet } from 'react-helmet';

export default function Community() {

    useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

    return (
        <div>
		 <Helmet>
         <title>CARIN serves a wide range of products across therapies and intends to add many more to its portfolio.</title>
        <meta name="title" content="CARIN serves a wide range of products across therapies and intends to add many more to its portfolio." />
        <meta name="description" content="We at CARIN are advocators of Science and using it to advance health is our calling.
									We are an assistive, Digital-First Healthcare brand. We believe that technology can be a great leveler and make Quality Healthcare much more accessible. We strive for a better future, for all." />
        <link rel="canonical" href="https://www.carin.life/community" />
        </Helmet>
            <section className="ls section_padding_bottom_50 columns_padding_25">
				<div className="container">
					<div className="row">
						<div className="col-sm-12">
							<div className="starting text-center">
                                <Carousel showArrows={true} autoPlay autoFocus dynamicHeight infiniteLoop interval={"4000"}>
                                    <Paper>
										<img
										className="d-block w-100"
										src={process.env.REACT_APP_IMAGES+ "/community_1.jpg"}
										alt="First slide"
										/>
                                    </Paper>
                                    <Paper>
									<LazyLoad>
                                        <img
                                        className="d-block w-100"
                                        src={process.env.REACT_APP_IMAGES+ "/community_2.jpg"}
                                        alt="Second slide"
                                        />
									</LazyLoad>
                                    </Paper>
                                </Carousel>
								{/* <div className="flexslider text-center" data-nav="false">
									<ul className="slides">
										<li> <img src="https://d214gcurai71bm.cloudfront.net/carin-images/stock-images/Our_Community.jpg" alt="" /> </li>
										<li> <img src="https://d214gcurai71bm.cloudfront.net/carin-images/stock-images/Our_Community_II.jpg" alt="" /> </li>
									</ul>
								</div> */}
							</div>
						</div>
					</div>
					<br />
					<div className="row">
						<div className="col-sm-12">
							<article>
								<div style={{padding: "20px"}} className="col-sm-12 to_animate bg-color-abt" data-animation="fadeInLeft" data-delay="150">
									<h3 className=" logo-color1">Our Community </h3>
									<div className="entry-excerpt">
										<p style={{fontSize: "22px"}}>Patients and Healthcare Professionals are at the heart of what we do.</p>
									</div>
									<br />
									<p>We value the expertise of our healthcare professionals to better understand patient needs. We interact with them to gather information about our products as well as valuable scientific, medical and educational information. At every moment we strive to enable our Healthcare Professionals and include them in our shared mission to be with the patients, every step of the way. Their clinical and disease management experience comes to our aid in improving the quality of patient care.</p>	
								</div>
							</article>
						</div>
					</div>
				</div>
			</section>
        </div>
    )
}