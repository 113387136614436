const siteUrl = "https://www.carin.life";
// const siteUrl = "http://localhost:3000";

const SitePages = [
  {
    pageName: "Home",
    pageUrl: `${siteUrl}/`,
  },
  {
    pageName: "About",
    pageUrl: `${siteUrl}/about`,
  },
  {
    pageName: "OFFERINGS",
    pageUrl: `${siteUrl}/offerings`,
    subPages: [
      {
        subPageName: "GYNAECOLOGY",
        subPageUrl: `${siteUrl}/offerings/gynaecology`,
        products: [
          {
            productName: "CLOMARIN",
            productUrl: `${siteUrl}/gynaecology/clomarin`,
          },
          {
            productName: "FOLFERIN-XT",
            productUrl: `${siteUrl}/gynaecology/folferin-xt`,
          },
          {
            productName: "PROGEIN 200",
            productUrl: `${siteUrl}/gynaecology/progein-200`,
          },
          {
            productName: "CALVITIN D-3",
            productUrl: `${siteUrl}/gynaecology/calvitin-d3`,
          },
          //   {
          //     productName: "BFOLIN",
          //     productUrl: `${siteUrl}/gynaecology/bfolin`,
          //   },
          {
            productName: "YAY-PROTEIN",
            productUrl: `${siteUrl}/gynaecology/yay-protein`,
          },
          {
            productName: "VITCIN-D3",
            productUrl: `${siteUrl}/gynaecology/vitcin-d3`,
          },
        ],
      },
      {
        subPageName: "CARDIOVASCULAR METABOLICS",
        subPageUrl: `${siteUrl}/offerings/cardiovascular_metabolics`,
        products: [
          {
            productName: "ROSURIN-5",
            productUrl: `${siteUrl}/cardiovascular/rosurin-5`,
          },
          {
            productName: "TELSARIN-20",
            productUrl: `${siteUrl}/cardiovascular/telsarin-20`,
          },
          {
            productName: "TELSARIN-AM",
            productUrl: `${siteUrl}/cardiovascular/telsarin-am`,
          },
        ],
      },
      {
        subPageName: "ANTI-DIABETICS",
        subPageUrl: `${siteUrl}/offerings/anti-diabetics`,
        products: [
          {
            productName: "GLIMIRIN M1",
            productUrl: `${siteUrl}/diabetics/glimirin-m1`,
          },
          {
            productName: "TENELIPT M500",
            productUrl: `${siteUrl}/diabetics/tenelipt-m500`,
          },
          {
            productName: "GLIMIRIN MV1",
            productUrl: `${siteUrl}/diabetics/glimirin-mv1`,
          },
        ],
      },
      {
        subPageName: "RESPIRATORY",
        subPageUrl: `${siteUrl}/offerings/respiratory`,
        products: [
          {
            productName: "SINARIN",
            productUrl: `${siteUrl}/respiratory/sinarin`,
          },
          {
            productName: "COFFRIN",
            productUrl: `${siteUrl}/respiratory/coffrin`,
          },
          {
            productName: "COFFRIN-DX",
            productUrl: `${siteUrl}/respiratory/coffrin-dx`,
          },
          {
            productName: "FEXRIN-180",
            productUrl: `${siteUrl}/respiratory/fexrin-180`,
          },
          {
            productName: "MONTRIN-LC",
            productUrl: `${siteUrl}/respiratory/montrin-lc`,
          },
        ],
      },
      {
        subPageName: "DERMATOLOGY",
        subPageUrl: `${siteUrl}/offerings/dermatology`,
        products: [
          {
            productName: "LULIRIN",
            productUrl: `${siteUrl}/dermatology/lulirin`,
          },
          {
            productName: "CANDRIN-100",
            productUrl: `${siteUrl}/dermatology/candrin-100`,
          },
        ],
      },
      {
        subPageName: "ANALGESICS",
        subPageUrl: `${siteUrl}/offerings/analgesics`,
        products: [
          {
            productName: "ACEPARIN-SP",
            productUrl: `${siteUrl}/analgesics/aceparin-sp`,
          },
          {
            productName: "MOVIRIN-GEL",
            productUrl: `${siteUrl}/analgesics/movirin-gel`,
          },
          {
            productName: "ACEPARIN",
            productUrl: `${siteUrl}/analgesics/aceparin`,
          },
          {
            productName: "ETCOXIN-TH",
            productUrl: `${siteUrl}/analgesics/etcoxin-th`,
          },
        ],
      },
      {
        subPageName: "ANTI-INFECTIVES",
        subPageUrl: `${siteUrl}/offerings/anti-infectives`,
        products: [
          {
            productName: "MOXYRIN CV",
            productUrl: `${siteUrl}/infectives/moxyrin-cv`,
          },
          {
            productName: "CAZIT 250",
            productUrl: `${siteUrl}/infectives/cazit-250`,
          },
          {
            productName: "PODORIN 200",
            productUrl: `${siteUrl}/infectives/podorin-200`,
          },
          {
            productName: "C-FIXIN 200",
            productUrl: `${siteUrl}/infectives/c-fixin-200`,
          },
        ],
      },
      {
        subPageName: "GASTROLOGY",
        subPageUrl: `${siteUrl}/offerings/gastrology`,
        products: [
          {
            productName: "PANRIN-40",
            productUrl: `${siteUrl}/gastrology/panrin-40`,
          },
          {
            productName: "PANRIN-D",
            productUrl: `${siteUrl}/gastrology/panrin-d`,
          },
          {
            productName: "RABERIN-20",
            productUrl: `${siteUrl}/gastrology/raberin-20`,
          },
          {
            productName: "RABERIN-D",
            productUrl: `${siteUrl}/gastrology/raberin-d`,
          },
          {
            productName: "RABERIN-L",
            productUrl: `${siteUrl}/gastrology/raberin-l`,
          },
        ],
      },
      {
        subPageName: "VITAMINS",
        subPageUrl: `${siteUrl}/offerings/vitamins`,
        products: [
          {
            productName: "CARNEO-FORTE",
            productUrl: `${siteUrl}/vitamins/carneo-forte`,
          },
          {
            productName: "VIBIN",
            productUrl: `${siteUrl}/vitamins/vibin`,
          },
        ],
      },
    ],
  },
  {
    pageName: "COMMUNITY",
    pageUrl: `${siteUrl}/community`,
  },
  {
    pageName: "BLOGS",
    pageUrl: `${siteUrl}/blogs`,
    subPages: [
      {
        subPageName: "Cardiovascular Diseases",
        subPageUrl: `${siteUrl}/blogs/cardiovascular_diseases`,
      },
      {
        subPageName: "Covid Vaccination",
        subPageUrl: `${siteUrl}/blogs/covid_vaccination`,
      },
      {
        subPageName: "Self Care",
        subPageUrl: `${siteUrl}/blogs/self-care`,
      },
      {
        subPageName: "Diabetes",
        subPageUrl: `${siteUrl}/blogs/diabetes`,
      },
      {
        subPageName: "High Blood Sugar",
        subPageUrl: `${siteUrl}/blogs/high_blood_sugar`,
      },
      {
        subPageName: "Hypertension",
        subPageUrl: `${siteUrl}/blogs/hypertension`,
      },
    ],
  },
  {
    pageName: "CAREERS",
    pageUrl: `${siteUrl}/careers`,
  },
  {
    pageName: "CONTACT",
    pageUrl: `${siteUrl}/contact`,
  },
];

export default SitePages;
