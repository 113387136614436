import React, { useEffect, useState } from "react";
import OfferingsCarousel from "./OfferingsCarousel";
import OfferingsDisplay from "./OfferingsDisplay";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Helmet } from "react-helmet";

export default function Diabetics() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [offerings, setOfferings] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchOfferings = async () => {
      try {
        setOfferings(offerings);
        const response = await axios.get(
          `${process.env.REACT_APP_API}/offerings/diabetics`
        );
        setOfferings(response.data);
      } catch (e) {
        console.log(e);
        setOfferings(offerings);
      }
    };

    fetchOfferings();
  }, []);

  return (
    <div>
    <Helmet>
         <title>Healthcare Products for Diabetes & It's Therapy - CARIN Life </title>
        <meta name="title" content="Healthcare Products for Diabetes & It's Therapy - CARIN Life" />
        <meta name="description" content="Diabetes is a metabolic disease characterized by high blood glucose (hyperglycaemia) due to insulin deficiency. Checkout products offered by CARIN Life for Diabetes." />
        </Helmet>
      <section className="ls section_padding_top_25">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              {" "}
              <img
                src={process.env.REACT_APP_IMAGES + "/diabetes.jpg"}
                alt=""
                className="alignleft offering_img"
              />
              <div
                className="to_animate section_padding_top_150"
                data-animation="fadeInLeft"
                data-delay="150"
              >
                <h3 className="section_header">Diabetes and its Therapy</h3>
                <p>
                  Diabetes is a metabolic disease characterized by high blood
                  glucose (hyperglycemia) due to insulin deficiency.
                  Uncontrolled high blood sugar is associated with diseases like
                  retinopathy, neuropathy, nephropathy, coronary artery disease
                  (CAD), heart attack, stroke etc. and it significantly reduced
                  life expectancy. According to the International Diabetes
                  Federation (IDF), globally around 463 million and in India 55
                  million adults had diabetes. In 2019, over 4.2 million died of
                  diabetes and its associated diseases [1].
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div
              className="col-sm-12 to_animate"
              data-animation="fadeInLeft"
              data-delay="150"
            >
              <h3 className="logo-color1 product_header">Products of Carin:</h3>
            </div>
          </div>
        </div>
      </section>
      <section
        id="products"
        className="offerings ds color parallax page_gallery columns_padding_25 to_animate"
        data-animation="fadeInLeft"
        data-delay="150"
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <Carousel
                showDots={false}
                responsive={responsive}
                transitionDuration={500}
                containerClass="carousel-container"
                itemClass="carousel-item-padding-40-px"
              >
                {offerings.map((offering) => (
                  <OfferingsCarousel data={offering} />
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </section>

      <section className="ls section_padding_bottom_50">
        <div className="container">
          <div className="row">
            {offerings.length &&
              offerings.map((offering, index) => {
                return <OfferingsDisplay offering="diabetics" data={offering} idx={index + 1} />;
              })}
          </div>
        </div>
      </section>

      <section className="ls section_padding_bottom_75">
        <div className="container">
          <div className="row">
            <div
              className="col-sm-12 to_animate"
              data-animation="fadeInUp"
              data-delay="150"
            >
              <h4 className="logo-color1">Management of Diabetes</h4>
              <h5 className="txt-bold logo-color2">Lifestyle Adjustments:</h5>
              <p>
                Healthy diet, physical activities, stress management, proper
                sleep, avoid smoking and drinking (alcohol) can significantly
                help to mange blood sugar level.{" "}
              </p>
              <h5 className="txt-bold logo-color2">Medications:</h5>
              <p>
                When lifestyle adjustments aren't enough for managing diabetes
                then blood sugar lowering medications are recommended.
                Effectiveness of diabetes medications depends on the timing and
                size of the dose.
              </p>
            </div>
            <div
              style={{ paddingTop: "30px" }}
              className="col-sm-12 to_animate"
              data-animation="fadeInUp"
              data-delay="150"
            >
              <div id="accordion3" className="panel-group collapse-unstyled">
                <div className="panel">
                  <h4 className="poppins logo-color1">
                    {" "}
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion3"
                      href="#collapse11"
                    >
                      References:
                    </a>{" "}
                  </h4>
                  <div id="collapse11" className="panel-collapse collapse">
                    <div className="panel-content">
                      {" "}
                      <ol className="list1 no-bullets">
                        <li>
                          Association A. 10. Microvascular complications and
                          foot care: standards of medical care in diabetes-2018.
                          Diabetes Care. 2018;41:S105.
                        </li>
                        <li>
                          Sahay RK, Mittal V, Gopal GR, Kota S, Goyal G,
                          Abhyankar M, et al. Glimepiride and Metformin
                          Combinations in Diabetes Comorbidities and
                          Complications: Real-World Evidence. Cureus.
                          2020;12(9).
                        </li>
                        <li>
                          Kim Hs, Kim Dm, Cha Bs, Park TS, Kim Ka, Kim Dl, et
                          al. Efficacy of glimepiride/metformin fixed‐dose
                          combination vs metformin uptitration in type 2
                          diabetic patients inadequately controlled on low‐dose
                          metformin monotherapy: A randomized, open label,
                          parallel group, multicenter study in K orea. Journal
                          of diabetes investigation. 2014;5(6):701-8.
                        </li>
                        <li>
                          Sharma SK, Panneerselvam A, Singh K, Parmar G, Gadge
                          P, Swami OC. Teneligliptin in management of type 2
                          diabetes mellitus. Diabetes, metabolic syndrome and
                          obesity: targets and therapy. 2016;9:251.
                        </li>
                        <li>
                          Murti K, Sethi MK, Dey A, Lal CS, Pandey K, Das P.
                          Addition of voglibose to glimepiride and metformin
                          have better glucose control in diabetics: A
                          prospective, parallel-group and open-label comparative
                          study. International Journal of Pharmacology.
                          2016;12(4):422-8.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
