import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          CARIN serves a wide range of products across therapies and intends to
          add many more to its portfolio.
        </title>
        <meta
          name="title"
          content="CARIN serves a wide range of products across therapies and intends to add many more to its portfolio."
        />
        <meta
          name="description"
          content="We at CARIN are advocators of Science and using it to advance health is our calling.
									We are an assistive, Digital-First Healthcare brand. We believe that technology can be a great leveler and make Quality Healthcare much more accessible. We strive for a better future, for all."
        />
        <link rel="canonical" href="https://www.carin.life/about" />
      </Helmet>
      <section className="page_breadcrumbs ds color parallax section_padding_bottom_75">
        <div className="starting">
          <img src={process.env.REACT_APP_IMAGES + "/aboutus.jpg"} alt="" />
        </div>
      </section>
      <section className=" ls about_section section_padding_top_40 section_padding_bottom_40">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="row about_us">
                <div
                  className="col-sm-12 to_animate bg-color-abt"
                  style={{ padding: "20px" }}
                  data-animation="fadeInLeft"
                  data-delay="150"
                >
                  <h3 className="logo-color1">About Carin</h3>
                  <p>
                    We at CARIN are advocators of Science and using it to
                    advance health is our calling.
                  </p>
                  <p>
                    We are an assistive, Digital-First Healthcare brand. We
                    believe that technology can be a great leveler and make
                    Quality Healthcare much more accessible. We strive for a
                    better future, for all.
                  </p>
                </div>
                <div
                  className="col-sm-12 to_animate bg-color-abt"
                  style={{ padding: "20px" }}
                  data-animation="fadeInRight"
                  data-delay="150"
                >
                  <h3 className="logo-color1">Mission</h3>
                  <p>
                    Our Mission is to transform billions of lives for the
                    better. We want to extend the joy of living beyond
                    constraints and boundaries.
                  </p>
                  <p>
                    We believe that all Patients are entitled to affordable
                    Quality Medicines. We also understand that Healthcare is
                    much more than Medicines and so, we continuously work
                    towards developing holistic patient support.
                  </p>
                  <p>
                    We want to bring about a genuine Transformation in the
                    Patient Journey.
                  </p>
                  <p>We’re passionate at what we do and the way we do it.</p>
                </div>
                <div
                  className="col-sm-12 to_animate bg-color-abt"
                  style={{ padding: "20px" }}
                  data-animation="fadeInLeft"
                  data-delay="150"
                >
                  <h3 className="logo-color1">What CARIN stands for</h3>
                  <strong className="inline">
                    <h4 className="logo-color2">C</h4>&nbsp; – Carin is Caring{" "}
                  </strong>{" "}
                  <br />
                  <strong className="inline">
                    <h4 className="logo-color2">A</h4>&nbsp; – Carin is about
                    being Assistive{" "}
                  </strong>
                  <br />
                  <strong className="inline">
                    <h4 className="logo-color2">R</h4>&nbsp; – Carin is being
                    Responsive{" "}
                  </strong>
                  <br />
                  <strong className="inline">
                    <h4 className="logo-color2">I</h4>&nbsp; – Carin is
                    Innovative{" "}
                  </strong>
                  <br />
                  <strong className="inline">
                    <h4 className="logo-color2">N</h4>&nbsp; – Carin is the
                    Novel way of Healthcare.
                  </strong>
                </div>
                <div
                  className="col-sm-12 to_animate bg-color-abt"
                  style={{ padding: "20px" }}
                  data-animation="fadeInRight"
                  data-delay="150"
                >
                  <h3 className="logo-color1">Code of Conduct</h3>
                  <h4 className="logo-color1">
                    <u>Our TIER – 1 Guiding Principles</u>
                  </h4>
                  <p>
                    <b>Trust and Transparency</b> – We’re trust-worthy and
                    reliable to all our stakeholders in all our engagements. Our
                    communication is simple, honest and transparent.
                  </p>
                  <p>
                    <b>Integrity</b> – Every fiber of our being is guided by
                    moral compass. We are committed to pursuing our business
                    objectives with complete integrity, with zero tolerance for
                    ethical violations.
                  </p>
                  <p>
                    <b>Excellent Quality</b> – We seek to provide Quality in all
                    our offerings. We are uncompromising in our pursuit of
                    quality healthcare.
                  </p>
                  <p>
                    <b>Responsibility</b> – We act responsibly towards all our
                    stakeholders, that includes patients, healthcare
                    professionals, regulators, business partners and investors.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
